import { Card, CardContent } from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import React, { useState } from 'react';
import { ctxData } from '../../../../utils/config';
import { getEmployeeId, getSenderDetailInChat, getSignedUrl } from '../../../../utils/customFunctions';
import { useLandingPageContext } from '../../../../utils/customHooks';
import FilePreview from '../../../customComponents/filePreview';
import Loader from '../../../customComponents/loader';
import '../index.css';
import MyMessage from './myMessage';
import PostMessage from './postMessage';
import ReceivedMessage from './receivedMessage';

const ChatFeed = () => {
    const data = useLandingPageContext();
    const [signedUrl, setSignedUrl] = useState({
        open: false,
        url: "",
        fileType: "",
    });
    const onPreview = async (id, fileType) => {
        const url = await getSignedUrl(id)
        if (url) setSignedUrl({
            open: true,
            url: url,
            fileType: fileType
        })
    }
    return (
        <Card className="chatfeed_container_card p-2">
            <CardContent>

                {data?.chatFeed?.chatHistoryLoader
                    ?
                    <Loader />
                    :
                    <>
                        {data?.chatFeed?.chatHistory?.map((chat, key) => (
                            data?.ctx === ctxData.type2 ?
                                <PostMessage userDetail={getSenderDetailInChat(chat?.sender)} onPreview={onPreview} landingPageData={data} chat={chat} key={key} />
                                :
                                chat?.sender === getEmployeeId() ?

                                    <MyMessage onPreview={onPreview} landingPageData={data} chat={chat} key={key} />
                                    :
                                    <ReceivedMessage onPreview={onPreview} landingPageData={data} chat={chat} userDetail={getSenderDetailInChat(chat?.sender)} key={key} />

                        ))}

                        <Box className="chat_feed_scroll p-2" ></Box>
                        <Box className="chat_feed_scroll" ref={data?.chatFeed?.ref}></Box>
                    </>
                }
                <FilePreview
                    open={signedUrl?.open}
                    signedUrl={signedUrl?.url}
                    fileType={signedUrl?.fileType}
                    closePreview={() =>
                        setSignedUrl({
                            ...signedUrl,
                            open: false,
                        })
                    }
                />
            </CardContent>
        </Card>
    )
}

export default ChatFeed
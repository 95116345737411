import React, { useEffect, useRef, useState } from 'react'
import { v4 as uuidv4 } from "uuid";
import { Box, Button, Grid, Stack } from '@mui/material'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { createAndGetUpdateConversationAction, createAndGetUpdatedConversationListAction, getChatHistory, getConversationIdAction, getMyConversationAction, selectConversationByCoIdAction, sendMessageAction, updateChatReducer, updateCommonReducer } from '../../actions/actions';
import ChatBox from '../../components/landingPage/chatBox';
import ConversationList from '../../components/landingPage/conversationList';
import VerticalAppBar from '../../components/landingPage/verticalNavbar'
import NavBar from '../../components/navigationBar';
import { employeeListService } from '../../services/proxyServices';
import './index.css';
import { useAgoraRTM } from '../../utils/customHooks';
import moment from 'moment';
import { scrollToBottom } from '../../utils/globalFunction';
import history, { coIdData, ctxData, menuData } from '../../utils/config';
import { getEmployeeId, getIsConverstionIdInList } from '../../utils/customFunctions';
import { handlingReceivedMessages } from '../../utils/customChatFunctions';
import FileListTable from '../../components/landingPage/chatBox/fileList';
import { commentPostAction, getChatAttachmentsListAction } from '../../actions/apiCallAction';
import Loader from '../../components/customComponents/loader';
export const LandingPageContext = React.createContext()
function LandingPage() {
    const { search } = useLocation();
    const { menu } = useParams();
    const navigate = useNavigate()
    const { participants,
        conversationListLoader,
        conversationId,
        chatHistory,
        chatHistoryLoader,
        selectedConversation,
        allConversationAndChannelList,
        updateMessageById,
        conversationList,
        channelList,
        webinarList,
    } = useSelector((state) => state.chat)
    const { conversationListUnRead, channelListUnRead } = useSelector((state) => state.persist)
    const { firstRenderLandingPage } = useSelector((state) => state.common)
    const searchParams = new URLSearchParams(search);
    const ctx = searchParams.get('ctx');
    const coId = searchParams.get('co_id');
    const { client,
        channel,
        sendMessage,
        message,
        joinChannel,
        receivedMessage,
        memberJoined,
        memberLeft,
        createdChannelList

    } = useAgoraRTM()
    useEffect(() => {
        if (receivedMessage) {
            if (receivedMessage.message.refresh) updateChatReducer("receivedMsgIdToUpdateMsg", receivedMessage?.message?.id)
            else handlingReceivedMessages({ message: receivedMessage, coId: coId, callBack: scrollToBottom(ref) });
        }
    }, [receivedMessage])

    const [employeeList, setEmployeeList] = useState([]);
    const [list, setList] = useState([])
    const ref = useRef()
    const selectConversation = () => {
        updateChatReducer("isConversationCreated", null)
        if (participants?.length > 0) selectConversationByCoIdAction(navigate, data)
    }
    const updateChatHistory = (msg) => {
        const chatHis = [...chatHistory, msg];
        updateChatReducer("chatHistory", chatHis);
        scrollToBottom(ref);
    }
    const sendMessageTo = async (msg, channelId) => {
        let addedMsg = msg;
        addedMsg.createdOn = moment().toISOString()
        const getObjId = await sendMessageAction(msg, channelId, addedMsg.id, ctx);
        if (getObjId) {
            addedMsg.id = getObjId
            updateChatHistory(addedMsg)
        }
        const isSent = await sendMessage(addedMsg, channelId);
    }
    const sendMessageLikeAndCommentUpdate = async (msg, channelId) => {
        console.log(msg, channelId, 'msg, channelIdmsg, channelIdmsg, channelId');
        const isSent = await sendMessage(msg, channelId);
    }

    const openChat = (coId) => {
        navigate(`/${menu}?co_id=${coId}&ctx=${ctx}`)
    }
    const onClickMenu = (menu) => {
        navigate(`/conversation?ctx=${menu}`)
    }
    const [fileList, setFileList] = useState([]);
    const getAllFilesList = async () => {
        try {
            updateChatReducer("chatHistoryLoader", true)
            const res = await getChatAttachmentsListAction({ createdBy: getEmployeeId() })
            updateChatReducer("chatHistoryLoader", false)
            if (res) setFileList(res)
        } catch (error) {
            updateChatReducer("chatHistoryLoader", false)

        }

    }
    const clearData = () => {
        updateChatReducer("chatHistory", []);
        updateChatReducer("participants", []);
        updateChatReducer("isReplay", { isReplay: false, chat: null })
    }
    const data = {
        ctx: ctx,
        coId: coId,
        menu: menu,
        navigate: navigate,
        sendMessageLikeAndCommentUpdate: sendMessageLikeAndCommentUpdate,
        VerticalAppBar: {
            onClickMenu: onClickMenu,
            conversationListUnRead: conversationListUnRead,
            channelListUnRead: channelListUnRead,
            getAllFilesList: getAllFilesList,
            clearData: clearData
        },
        ConversationList: {
            conversationListLoader: conversationListLoader,
            conversationList: list,
            conversationId: conversationId,
            openChat: openChat,
            clearChatHistory: () => updateChatReducer("chatHistory", []),
            conversationListUnRead: conversationListUnRead,
            channelListUnRead: channelListUnRead,
        },
        chatBox: {
            createAndGetUpdatedConversationList: createAndGetUpdatedConversationListAction
        },
        chatBoxHeader: {
            employeeList: employeeList,
            participants: participants,
            getConversationId: getConversationIdAction,

        },
        chatFeed: {
            chatHistory: chatHistory,
            chatHistoryLoader: chatHistoryLoader,
            scrollToBottom: scrollToBottom,
            ref: ref,
            selectedConversation: selectedConversation,
            updateMessageById: updateMessageById,
        },
        ChatBoxFooter: {
            sendMessage: sendMessageTo,
            selectConversation: selectConversation,
            commentPostAction: commentPostAction,
        },
        updateChatReducer: updateChatReducer

    }
    const getEmployeeList = async () => {
        const data = {
            itemsPerPage: 0,
            pageNumber: 0,
            searchCriteria: { role: [] }
        }
        const res = await employeeListService(data)
        if (res) setEmployeeList(res)
    }
    useEffect(() => {
        updateCommonReducer("firstRenderLandingPage", false);
        if (!firstRenderLandingPage) {
            console.log(coId, "%ccoid", "background: 'red;")
            getEmployeeList();
            getMyConversationAction(ctxData.type1)
            getMyConversationAction(ctxData.type2)
            getMyConversationAction()
        }
    }, [firstRenderLandingPage])
    // useEffect(() => {
    //     if (allConversationAndChannelList?.length > 0) {
    //         allConversationAndChannelList?.map((conversation) => joinChannel(conversation?.channelId))
    //     }
    // }, [allConversationAndChannelList])
    useEffect(() => {
        if (conversationList?.length > 0 && client) {
            conversationList?.map((conversation) => joinChannel(conversation?.channelId))
        }
    }, [conversationList, client])
    useEffect(() => {
        if (channelList?.length > 0 && client) {
            channelList?.map((conversation) => joinChannel(conversation?.channelId))
        }
    }, [channelList, client])
    useEffect(() => {
        if (coId && coId !== coIdData.type1 && coId !== coIdData.type2) {
            const callBack = async (coId) => {
                updateChatReducer("participants", []);
                getChatHistory(coId, ctx);
                // ctx === ctxData.type2 && joinChannel(coId)
            }
            callBack(coId)
        } else if (coId && (coId === coIdData.type1 || coId === coIdData.type2)) {
            updateChatReducer("chatHistory", [])
        }
    }, [coId])
    // on reload select previous selected datas
    useEffect(() => {
        if (coId && coId !== coIdData.type1 && coId !== coIdData.type2) {
            const callBack = async (coId) => {
                let data;
                if (conversationList?.length > 0 || channelList?.length > 0) data = await getIsConverstionIdInList(coId, ctx);
                if (data) updateChatReducer("selectedConversation", data);
            }
            callBack(coId)
        }
    }, [conversationList, coId, channelList])
    // onListeners returns Data handling
    // useEffect(() => {
    //     if (message) updateChatHistory(message)
    // }, [message])

    // on ctx co_id change
    useEffect(() => {
        if (!coId && menu !== menuData.type2) {
            ctx === ctxData.type1 && openChat(coIdData.type1)
            ctx === ctxData.type2 && openChat(coIdData.type2)
        }
    }, [coId, ctx])
    // will call this api on first render only
    // useEffect(() => {
    //     if (ctx) getMyConversationAction(ctx)
    // }, [ctx])
    // set conversation list dynamically based on ctx type
    useEffect(() => {
        if (ctx) {
            ctx === ctxData.type1 && setList(conversationList)
            ctx === ctxData.type2 && setList(channelList)
        }
    }, [ctx, conversationList, channelList])
    return (
        <LandingPageContext.Provider value={data}>
            <Stack
                direction="column"
                className="container"
            >
                <Box>
                    <NavBar />
                </Box>
                <Box className="chat_page_container">
                    <Grid container className="chat_page_container2">
                        <Grid item className="chat_page_item_no_scroll vertical_appbar">
                            <VerticalAppBar />
                        </Grid>
                        {menu === menuData.type2 ?
                            <Grid item lg md xs className="chat_page_item_scroll p-1">
                                {chatHistoryLoader ?
                                    <Loader />
                                    : <FileListTable data={fileList} />}
                            </Grid>
                            :
                            <>
                                <Grid item className="chat_page_item_scroll">
                                    <ConversationList />
                                </Grid>
                                <Grid item lg md sm xs className="chat_page_item_scroll">
                                    <ChatBox />
                                </Grid>
                            </>
                        }
                    </Grid>
                </Box>
                {/* <Box>Footer</Box> */}
            </Stack>
        </LandingPageContext.Provider>
    )
}

export default LandingPage
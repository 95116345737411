import { Avatar, Button, Divider, Grid, IconButton, InputBase, LinearProgress, ListItem, ListItemText, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import CustomListItem from '../../../customComponents/CustomListItem';
import fileImg from "../../../../assets/images/filePreview/file.png"
import { DownloadFileWithFileName, getEmployeeId, getEmployeeName, getSenderDetailInChat, updateChatData } from '../../../../utils/customFunctions';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import ThumbUpComponent from './thumbUp';
import { ctxData, errorMessages } from '../../../../utils/config';
import ReplayComponent from './replayComponent';
import moment from 'moment';
import { styles } from '../../../../utils/styles';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { updateChatReducer, uploadFileAction } from '../../../../actions/actions';
import ReplyIcon from '@mui/icons-material/Reply';
import { useSelector } from 'react-redux';

import fileIcon from '../../../../assets/images/filePreview/file.png'
import CloseIcon from '@mui/icons-material/Close';
import { getChatDataByIdAction } from '../../../../actions/apiCallAction';
const PostMessage = ({ chat, onPreview, landingPageData, userDetail }) => {
    const { chatIdForPostReplayEnable, receivedMsgIdToUpdateMsg } = useSelector(state => state.chat)
    const [chatData, setChatData] = useState(chat);
    const [replay, setReplay] = React.useState({
        channelId: chat?.channelId,
        sender: getEmployeeId(),
        message: "",
        mentions: [],
        attachments: [],
    })
    const [isError, setIsError] = React.useState({
        open: false,
        msg: ''
    });
    const [load, setLoad] = React.useState(0)
    const [file, setFile] = useState()
    const onPressReplay = (id) => {
        updateChatReducer("chatIdForPostReplayEnable", id)
    }
    const showError = (status, msg) => {
        if (status) {
            setIsError({
                open: true,
                msg: msg
            })
            setTimeout(() => showError(false), 2000)
        } else setIsError({ open: false, msg: "" })

    }
    const clearAttachment = () => {
        setFile(null)
        setReplay({
            ...replay,
            attachments: [],
        })
    }
    const sendReplayMessage = async () => {
        if (replay?.message?.length === 0 && replay?.attachments?.length === 0) showError(true, errorMessages.emptyMsg)
        else {
            const res = await landingPageData?.ChatBoxFooter?.commentPostAction(replay, chat?.channelId, chat?.id);

            // updateChatReducer("isReplay", { isReplay: false, chat: null })
            if (res) {
                updateChatReducer("chatIdForPostReplayEnable", null);
                updateChatData({ channelId: chat?.channelId, objId: chat?.id, setState: setChatData });
                const commentData = {
                    channelId: chatData?.channelId,
                    message: `commented by ${getEmployeeName()}`,
                    sender: getEmployeeId(),
                    refresh:true,
                    id:chatData?.id
                }
                landingPageData.sendMessageLikeAndCommentUpdate(commentData, chatData?.channelId)
            }
        }
        resetMessage2()
    }
    const getAndSetFileName2 = async (file) => {
        setFile(file)
        const fileObj = await uploadFileAction(file, chat?.channelId, setLoad)
        if (fileObj) setReplay({
            ...replay,
            attachments: [...replay.attachments, { id: fileObj.id, file: fileObj }]
        })
    }
    const resetMessage2 = () => {
        setReplay({
            channelId: chat?.channelId,
            sender: getEmployeeId(),
            message: "",
            mentions: [],
            attachments: [],
            // isEdited: false,
            // isActive: true,
        })
        setFile(null)
    }
    useEffect(() => {
        if ( receivedMsgIdToUpdateMsg && receivedMsgIdToUpdateMsg === chat?.id) {
            updateChatData({ channelId: chat?.channelId, objId: chat?.id, setState: setChatData })
            updateChatReducer("receivedMsgIdToUpdateMsg", null);
        }
    }, [receivedMsgIdToUpdateMsg])
    useEffect(() => {
        console.log(chatData, 'chatDatachatDatachatDatachatDatachatDatachatDatachatData');
    },[chatData])
    return (
        <Box className="post_message_container">
            <ListItem className="flex align-items-start">
                <Avatar alt="image" src={userDetail?.src} className="me-1" />
                <Grid container className="flex">
                    <Grid item lg md xs className="post_message_container2">
                        <Paper className="post_replay_main_container">
                            <ListItemText
                                disableTypography
                                primary={
                                    <Box className="flex flex-end align-items-center">
                                        <Typography fontWeight="bold" fontSize="0.8rem" marginLeft={2} variant="subtitle2">
                                            {userDetail?.name}
                                        </Typography>
                                        <Typography fontSize="0.7rem" color="GrayText" marginLeft={2} variant="subtitle2">
                                            {moment(chatData?.createdOn).format('DD-MM-YYYY hh:mm A')}
                                        </Typography>
                                        <Box className="flex-1"></Box>
                                        {landingPageData?.ctx === ctxData.type2 &&
                                            <Box className="flex-1 d-flex flex-end">
                                                {/* <Typography style={{ float: 'right' }} fontSize="0.5rem" color="GrayText" marginLeft={2} variant="subtitle2">
                                                <ReplayComponent chatData={chatData} landingPageData={landingPageData} />
                                            </Typography> */}
                                                <Typography style={{ float: 'right' }} fontSize="0.5rem" color="GrayText" marginLeft={2} variant="subtitle2">
                                                    <ThumbUpComponent chatData={chatData} landingPageData={landingPageData} />
                                                </Typography>
                                            </Box>
                                        }
                                    </Box>
                                }
                                secondary={
                                    <Box className="post_message_text">
                                        <Typography className="word-break-all" marginLeft={2} marginTop={1} variant="subtitle2">
                                            {chatData?.message}
                                        </Typography>
                                    </Box>
                                }
                            />

                            {chatData?.attachments?.length > 0 &&
                                chatData?.attachments.map((file, key) => (
                                    <Box className="post_message_attchment_container" sx={{ p: 1 }}>
                                        <CustomListItem
                                            type="text"
                                            src={fileImg}
                                            key={key}
                                            primary={
                                                <span className="on_hover_text" style={{ cursor: 'pointer' }} onClick={() => onPreview(file?.file?.id, file?.file?.fileType)}>{file?.file?.filename}</span>
                                            }
                                            endIcon={
                                                <IconButton onClick={() => DownloadFileWithFileName(file?.file?.id, file?.file?.fileType, file?.file?.filename)}>
                                                    <DownloadForOfflineIcon />
                                                </IconButton>
                                            }
                                        />
                                    </Box>
                                ))
                            }
                        </Paper>
                        <Paper>
                            <Box className="p-1">
                                {
                                    chatData.comments?.map((item) => (
                                        <CustomListItem type="text"
                                            primary={userDetail?.name}
                                            primary2={
                                                <Typography marginLeft={2} noWrap textOverflow="ellipsis" fontSize="0.7rem" color="GrayText">
                                                    {moment(item?.createdOn).format('DD-MM-YYYY')}
                                                </Typography>
                                            }
                                            src={userDetail?.src}
                                            secondary={item?.message}
                                        // endIcon={(
                                        //     <ThumbUpComponent chatData={item} landingPageData={landingPageData} />
                                        // )}
                                        />
                                    ))

                                }
                            </Box>
                            {file &&
                                <Paper className="error_message_container">
                                    <Box className="attach_file_container">
                                        <CustomListItem type="text"
                                            primary={file?.name}
                                            src={fileIcon}
                                            endIcon={(
                                                <IconButton onClick={clearAttachment}>
                                                    <CloseIcon />
                                                </IconButton>
                                            )} />
                                    </Box>
                                    <Box className="attach_file_Loader">
                                        <LinearProgress variant="determinate" value={load} />
                                    </Box>
                                </Paper>
                            }
                            {isError.open &&
                                <Paper className="error_message_container">
                                    {isError.msg}
                                </Paper>}
                            {(chatIdForPostReplayEnable === chat?.id)
                                ?
                                <form onSubmit={(e) => {
                                    e.preventDefault()
                                    sendReplayMessage()
                                }}>
                                    <Paper
                                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%', boxShadow: styles.boxShadow_white }}
                                    >

                                        {/* <label htmlFor="upload-photo">
                                            <input
                                                style={{ display: 'none' }}
                                                id="upload-photo"
                                                name="upload-photo"
                                                type="file"
                                                onChange={(e) => {
                                                    if (e.target?.files?.length > 0) getAndSetFileName2(e.target?.files[0])
                                                }}
                                            />
                                            <Button component="span" sx={{ p: '10px' }}>
                                                <AttachFileOutlinedIcon />
                                            </Button>
                                        </label> */}

                                        <InputBase
                                            sx={{ ml: 1, flex: 1 }}
                                            placeholder="Type a new message"
                                            inputProps={{ 'aria-label': 'Type a new message' }}
                                            value={replay?.message}
                                            onChange={(e) => {
                                                setReplay({
                                                    ...replay,
                                                    message: e.target.value
                                                })
                                            }}
                                        />
                                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

                                        <Button type="submit" onClick={sendReplayMessage} component="span" sx={{ p: '10px' }}>
                                            <SendOutlinedIcon />
                                        </Button>
                                    </Paper>
                                </form>
                                :
                                <Box
                                    className="post_replay_container d-flex align-items-center"
                                    onClick={() => onPressReplay(chat?.id)}
                                >
                                    <ReplyIcon fontSize="small" />
                                    <Typography fontSize="small" sx={{ ml: 1 }} variant="subtitle2"> Reply</Typography>
                                </Box>

                            }

                        </Paper>
                    </Grid>

                </Grid>
            </ListItem >
        </Box>
    )
}

export default PostMessage
import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { channelTypeData } from '../../../utils/config';

const CreateGroup = ({disabled}) => {
    const { setValue, control, formState: { errors } } = useFormContext()
    return (
        <Paper className="p-1">
            <Grid container spacing={1}>
                <Grid item lg={5}>
                    <Controller
                        control={control}
                        name="channelName"
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <TextField
                                name="channelName"
                                label="Channel name"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant='outlined'
                                size='small'
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                inputRef={ref}
                                error={!!errors.channelName}
                                helperText={errors.channelName?.message}
                                disabled={disabled}
                            />
                        )}

                        rules={{ required: 'This field is required!' }}
                    />
                </Grid>
                <Grid item lg={5}>
                    <Controller
                        control={control}
                        name="conversationType"
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <FormControl fullWidth>
                                <InputLabel shrink id="demo-simple-select-label">Channel type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="appointmentSource"
                                    label="Channel type"
                                    variant='outlined'
                                    size='small'
                                    notched
                                    onChange={async (e) => {
                                        setValue('conversationType', e.target.value)
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    inputRef={ref}
                                    helperText={errors?.conversationType?.message}
                                    error={!!errors?.conversationType}
                                    disabled={disabled}
                                >
                                    {
                                        channelTypeData?.map((i, k) => (
                                            <MenuItem key={k} value={i?.value}>{i?.label}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        )}
                        
                        rules={{ required: 'This field is required!' }}
                    />
                </Grid>
                <Grid item lg={2}>
                    <Button disabled={disabled} type="submit" variant="contained">Create</Button>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default CreateGroup;
import { Avatar, Grid, IconButton, ListItem, ListItemText, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { DownloadFileWithFileName, updateChatData } from '../../../../utils/customFunctions'
import CustomListItem from '../../../customComponents/CustomListItem';
import fileImg from "../../../../assets/images/filePreview/file.png";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { ctxData } from '../../../../utils/config';
import ThumbUpComponent from './thumbUp';
import ReplayComponent from './replayComponent';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { updateChatReducer } from '../../../../actions/actions';

const ReceivedMessage = ({ chat, userDetail, onPreview, landingPageData }) => {
    const [chatData, setChatData] = useState(chat);
    const {receivedMsgIdToUpdateMsg} = useSelector(state => state.chat)
    useEffect(() => {
        if (receivedMsgIdToUpdateMsg && receivedMsgIdToUpdateMsg === chat?.id) {
            updateChatData({channelId:chat?.channelId, objId:chat?.id,setState:setChatData});
            updateChatReducer("receivedMsgIdToUpdateMsg", null);
        }
    }, [receivedMsgIdToUpdateMsg])
    return (
        <Box className="received_message_container">
            <ListItem className="flex align-items-start">
                <Avatar alt="image" src={userDetail?.src} className="me-1" />
                <Grid container >
                    <Grid item className="received_message_wrapper">
                        <ListItemText
                            disableTypography
                            primary={
                                <Box className="flex flex-start align-items-center">
                                    <Typography fontWeight="bold" fontSize="0.8rem" marginLeft={2} variant="subtitle2">
                                        {userDetail.name}
                                    </Typography>
                                    <Typography fontSize="0.7rem" color="GrayText" marginLeft={2} variant="subtitle2">
                                    {moment(chatData?.createdOn).format('DD-MM-YYYY hh:mm A')}
                                    </Typography>
                                    <Box className="flex-1"></Box>
                                    {landingPageData?.ctx === ctxData.type2 &&
                                        <Box className="flex-1 d-flex flex-end">
                                        <Typography style={{ float: 'right' }} fontSize="0.5rem" color="GrayText" marginLeft={2} variant="subtitle2">
                                        <ReplayComponent chatData={chatData} landingPageData={landingPageData} />
                                        </Typography>
                                        <Typography style={{ float: 'right' }} fontSize="0.5rem" color="GrayText" marginLeft={2} variant="subtitle2">
                                            <ThumbUpComponent chatData={chatData} landingPageData={landingPageData} />
                                        </Typography>
                                        </Box>
                                    }
                                </Box>
                            }
                            secondary={
                                <Typography className="word-break-all" marginLeft={2} variant="subtitle2">
                                    {chatData?.message}
                                </Typography>
                            }
                        />
                        {chatData?.attachments?.length > 0 &&
                            chatData?.attachments.map((file, key) => (
                                <Box className="my_message_attchment_container">
                                    <CustomListItem
                                    type="text"
                                        key={key}
                                        src={fileImg}
                                        primary={
                                            <span className="on_hover_text" style={{ cursor: 'pointer'}} onClick={() => onPreview(file?.file?.id, file?.file?.fileType)}>{file?.file?.filename}</span>
                                        }
                                        endIcon={
                                            <IconButton onClick={() => DownloadFileWithFileName(file?.file?.id,file?.file?.fileType,file?.file?.filename)}>
                                                <DownloadForOfflineIcon/>
                                            </IconButton>
                                        }
                                        />
                                </Box>
                            ))
                        }
                    </Grid>
                </Grid>
            </ListItem>
        </Box>
    )
}

export default ReceivedMessage
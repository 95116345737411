import { updateChatStates } from "../redux/reducers/chatDataReducer"
import { updateCommonStates } from "../redux/reducers/commonReducer";
import { updatePersistStates } from "../redux/reducers/persistReducer";
import store from "../redux/store"
import { createConversationService, getChatHistoryService, getConversationIdService, getMyConversationService, sendMessageService } from "../services/chatServices";
import { fileAttachmentUploadService, fileAttachmentUploadURLGenService } from "../services/fileService";
import history, { conversationType, ctxData } from "../utils/config";
import { getIsConverstionIdInList } from "../utils/customFunctions"

export const updateChatReducer = (stateName, value) => {
    store.dispatch(updateChatStates({ name: stateName, value: value }))
}
export const updatePersistReducer = (stateName, value) => {
    store.dispatch(updatePersistStates({ name: stateName, value: value }))
}
export const updateCommonReducer = (stateName, value) => {
    store.dispatch(updateCommonStates({ name: stateName, value: value }))
}

export const getLandingPageRequiredData = async () => {
    // getMyConversationAction();
}
export const getMyConversationAction = async (menu) => {
    let data = {
        searchCriteria: {
            conversationType__in: []
        }
    }
    if (menu === ctxData.type1) data.searchCriteria.conversationType__in = conversationType.type1
    else if (menu === ctxData.type2) data.searchCriteria.conversationType__in = conversationType.type2
    else if (menu === ctxData.type3) data.searchCriteria.conversationType__in = conversationType.type3
    const res = await getMyConversationService(data)
    if (res) {
        menu === ctxData.type1 && updateChatReducer("conversationList", res);
        menu === ctxData.type2 && updateChatReducer("channelList", res);
        menu === ctxData.type3 && updateChatReducer("webinarList", res);
        // !menu  && updateChatReducer("allConversationAndChannelList", res);
        return res
    }
}
export const getConversationIdAction = async (participants) => {
    const structuredData = [];
    participants?.map((item) => structuredData.push({ employee: item?.empId, role: "Member" }));
    const convId = await getConversationIdService({ participants: structuredData });
    return convId
    // if (convId) {
    //     updateChatReducer("conversationId", convId);
    //     getMyConversationAction(ctxData.type1)
    //     const data = await getIsConverstionIdInList(convId)
    //     if (data) updateChatReducer("selectedConversation", data)
    //     getChatHistory(convId);
    // }

}
export const createAndGetUpdatedConversationListAction = async (data, ctx, menu, navigate) => {
    const convId = await createConversationAction(data);
    if (convId) {
        navigate(`/${menu}?co_id=${convId}&ctx=${ctx}`)
        updateChatReducer("conversationId", convId);
        const res = await getMyConversationAction(ctx)
        const data = await getIsConverstionIdInList(convId, ctx);
        if (data) updateChatReducer("selectedConversation", data);
        getChatHistory(convId, ctx);
    }

}

export const createConversationAction = async (data) => {
    const convId = await createConversationService(data);
    return convId
}
export const selectConversationByCoIdAction = async (navigate, contextData) => {
    const { conversationId, participants } = store.getState().chat;
    const { ctx, menu } = contextData;
    try {
        updateCommonReducer("backDrop", true)
        if (conversationId) {
            // if (getIsConverstionIdInList(conversationId)) {
            navigate(`/${menu}?co_id=${conversationId}&ctx=${ctx}`)
            updateChatReducer("conversationId", "");
            // } 
            // else {
            //     const res = await getMyConversationAction(ctx);
            //     if (res) {
            //         navigate(`/${menu}?co_id=${conversationId}&ctx=${ctx}`)
            //         updateChatReducer("conversationId", "");
            //     }
            // }
        } else {
            if (participants?.length > 0) {
                const structuredData = [];
                const payLoad = {
                    participants: structuredData,
                    // conversationType: formData?.conversationType,
                    // channelName: formData?.channelName
                }
                participants?.map((item) => structuredData.push({ employee: item?.empId, role: "Member" }));
                const res = await createConversationAction(payLoad)
                if (res) {
                    const res1 = await getMyConversationAction(ctx);
                    if (res1) {
                        navigate(`/${menu}?co_id=${res}&ctx=${ctx}`)
                        updateChatReducer("conversationId", "");
                    }
                }
            }

        }

        updateCommonReducer("backDrop", false)
    } catch (error) {
        updateCommonReducer("backDrop", false)
    }

}
export const getChatHistory = async (coId, ctx) => {
    let type = "chat";
    if (ctx === ctxData.type2) type ="post";
    const res = await getChatHistoryService(coId, type)
    if (res?.length > 0) updateChatReducer("chatHistory", res.reverse());
}

export const sendMessageAction = async (msg, channelId, uuid, ctx) => {
    let type = "chat";
   if (ctx === ctxData.type2) type = "post"
    const data = msg;
    const { conversationId } = store.getState().chat;
    const coId = channelId ? channelId : conversationId;
    const res = await sendMessageService(coId, data, type);
    return res;

}

// file service
export const uploadFileAction = async (file, channelId, setLoad) => {
    const { conversationId } = store.getState().chat
    const coId = channelId ? channelId : conversationId
    // const data = {
    //     id: coId,
    //     uploadFor: "employee",
    //     documentType: "chat",
    //     fileType: file.type,
    //     objectLocation: "private",
    //     apiType: "upload",
    // };
    const data = {
        id: coId,
        uploadFor: "employee",
        documentType: "chat",
        fileType: file.type,
        objectType: "community",
        // apiType: "upload",
        filename:file.name,
        fileSize:file.size,
        // objectLocation: "private",
    };
    const [url, fileName,fileObj] = await fileAttachmentUploadURLGenService(data);
    // console.log(url, fileName, 'data');
    const filename = await fileAttachmentUploadService(file, url, fileName, setLoad);
    return fileObj
}
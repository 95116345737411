import axios from 'axios';
import { v4 as uuidv4 } from "uuid";
import { getAuthToken, TriggerAlert } from '../utils/customFunctions';
// Add a request interceptor
axios.interceptors.request.use(
  function (request) {
    // Do something before request is sent
    const temp = request
    if (request.url.includes("storage.googleapis.com")) return temp;
    request.headers = {
      "Content-Type": "application/json",
      applicationLabel: "Chat-Service",
      correlationId: uuidv4(),
      Authorization: `Bearer ${getAuthToken()}`,
    }
    return request;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error?.response?.data?.code === 400) {
      if (error?.response?.data?.errors?.length > 0) TriggerAlert('error', error?.response?.data?.errors[0].message)
      else TriggerAlert('error', error?.response?.data?.message)
    }
    return Promise.reject(error);
  },
);
export default axios;

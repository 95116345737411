import { Button, Card, CardContent, CircularProgress, Container, Grid, TextField, Typography, Link } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import logo from '../../assets/images/loginPage/logo.svg'
import image from '../../assets/images/loginPage/image.svg'
import { ChangePasswordService, LoginService, SendOtpService } from '../../services/authServices';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TriggerAlert } from '../../utils/customFunctions';
import { colors } from '../../utils/styles';
const LoginPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [isAuth, setIsAuth] = useState(false)
    const [isLoad, setIsLoad] = useState(false)
    const [isLoad2, setIsLoad2] = useState(false)
    const [userName, setUserName] = useState("")
    const [isView, setIsView] = useState({
        login: true,
        sendOtp: false,
        changePassword: false
    })
    const form1 = useForm({
        defaultValues: {
            username: 'hellomail@xyz.com',
            password: '4649'
        }
    })
    const form2 = useForm({
        defaultValues: {
            otp: "",
            changePassword: "",
            confirmPassword: "",
        }
    })
    const sendOtp = async (e) => {
        e.preventDefault();
        const res = await SendOtpService(userName, setIsLoad)
        if (res) {
            TriggerAlert('success',res.data?.data )
            setIsView({
                changePassword: true
            })
        }

    }
    const { handleSubmit, formState: { errors } } = form1
    const onSubmit = (data) => {
        dispatch(LoginService(data, navigate, setIsAuth))
    }
    const onSubmit2 = async(data) => {
        const data2 = {
            username: userName,
            password: data.confirmPassword,
            otp: Number(data.otp)
        }
        const res = await ChangePasswordService(data2, setIsLoad2)
        if (res) {
            TriggerAlert('success', res.data?.data?.message)
            setIsView({
                login: true
            })
        }
    }
    const isSame = () => {
        const pass1 = form2.getValues("changePassword")
        const pass2 = form2.getValues("confirmPassword")
        return pass1 === pass2
    }
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: colors.background,
                height: '100vh'
            }}
        >
            <Container maxWidth="md">
                <Card variant='outlined'
                    sx={{
                        minWidth: 275,
                        minHeight: 500,
                        width: '100%',
                        boxShadow: '0 1px 3px #dcdfe2',
                        border: 0,
                        background: '#fff',
                        cursor: 'pointer',
                        px: 4,
                    }}
                >
                    <CardContent style={{ minHeight: 500 }}>
                        <Grid container >
                            <Grid item md={12} xs={12} lg={6}
                                display="flex"
                                flexDirection="column"
                                sx={{
                                    height: '100%',
                                    minHeight: 500,
                                }}
                            >
                                <Box
                                    mb={3}
                                    mt={5}
                                >
                                    <Box
                                        component="img"
                                        alt="logo"
                                        src={logo}
                                        sx={{

                                        }}
                                        mb={1}
                                    />
                                    <Typography variant='h5'>Sign in </Typography>
                                </Box>
                                {isView.login &&
                                    <Box>
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <FormProvider {...form1}>
                                                <Box mb={3}>
                                                    <Controller
                                                        name="username"
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <TextField
                                                                name="username"
                                                                label="Email"
                                                                type='email'
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                variant='outlined'
                                                                size='small'
                                                                onChange={onChange}
                                                                onBlur={onBlur}
                                                                value={value}
                                                                inputRef={ref}
                                                                error={!!errors.username}
                                                                helperText={errors.username?.message}

                                                            />
                                                        )}
                                                        rules={{ required: 'This field is required!' }}
                                                    />
                                                </Box>
                                                <Box mb={3}>
                                                    <Controller
                                                        name="password"
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <TextField
                                                                name="password"
                                                                label="Password"
                                                                type="password"
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                variant='outlined'
                                                                size='small'
                                                                onChange={onChange}
                                                                onBlur={onBlur}
                                                                value={value}
                                                                inputRef={ref}
                                                                error={!!errors.password}
                                                                helperText={errors.password?.message}

                                                            />
                                                        )}
                                                        rules={{ required: 'This field is required!' }}
                                                    />
                                                </Box>
                                                <Box mb={2}>
                                                    <Button variant="contained" type='submit' fullWidth>
                                                        {!isAuth ?
                                                            "Submit"
                                                            :
                                                            <CircularProgress size={25} style={{ color: '#fff' }} />
                                                        }
                                                    </Button>

                                                </Box>
                                                <Link onClick={() => setIsView({
                                                    sendOtp: true
                                                })}>Forgot Password?</Link>
                                            </FormProvider>
                                        </form>
                                    </Box>}
                                {isView.sendOtp &&
                                    <form onSubmit={sendOtp}>
                                        <Box mb={3}>
                                            <TextField
                                                name="username"
                                                label="Email"
                                                type='email'
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={userName}
                                                onChange={(e) => setUserName(e.target.value)}
                                                variant='outlined'
                                                size='small'
                                                required
                                            />
                                        </Box>
                                        <Box mb={2}>
                                            <Button variant="contained" type='submit' fullWidth>
                                                {!isLoad ?
                                                    "Send OTP"
                                                    :
                                                    <CircularProgress size={25} style={{ color: '#fff' }} />
                                                }
                                            </Button>
                                        </Box>
                                        <Link onClick={() => setIsView({
                                            login: true
                                        })}>Login Page</Link>
                                    </form>
                                }
                                {isView.changePassword &&
                                    <Box>
                                        <form onSubmit={form2.handleSubmit(onSubmit2)}>
                                            <FormProvider {...form2}>
                                                {/* <Box mb={3}>
                                                    <Controller
                                                        name="email"
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <TextField
                                                                name="email"
                                                                label="User Name"
                                                                type='email'
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                variant='outlined'
                                                                size='small'
                                                                onChange={onChange}
                                                                onBlur={onBlur}
                                                                value={value}
                                                                inputRef={ref}
                                                                error={!!form2.formState.errors.email}
                                                                helperText={form2.formState.errors.email?.message}

                                                            />
                                                        )}
                                                        rules={{ required: 'This field is required!' }}
                                                    />
                                                </Box> */}
                                                <Box mb={3}>
                                                    <Controller
                                                        name="otp"
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <TextField
                                                                name="otp"
                                                                label="OTP"
                                                                type="number"
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                variant='outlined'
                                                                size='small'
                                                                onChange={onChange}
                                                                onBlur={onBlur}
                                                                value={value}
                                                                inputRef={ref}
                                                                error={!!form2.formState.errors.otp}
                                                                helperText={form2.formState.errors.otp?.message}

                                                            />
                                                        )}
                                                        rules={{ required: 'This field is required!' }}
                                                    />
                                                </Box>
                                                <Box mb={3}>
                                                    <Controller
                                                        name="changePassword"
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <TextField
                                                                name="changePassword"
                                                                label="Change Password"
                                                                type="password"
                                                                autoComplete='new-password'
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                variant='outlined'
                                                                size='small'
                                                                onChange={onChange}
                                                                onBlur={onBlur}
                                                                value={value}
                                                                inputRef={ref}
                                                                error={!!form2.formState.errors.changePassword}
                                                                helperText={!!form2.formState.errors.changePassword && "Passwords do not match"}

                                                            />
                                                        )}
                                                        rules={{ required: true, validate: isSame }}
                                                    />
                                                </Box>
                                                <Box mb={3}>
                                                    <Controller
                                                        name="confirmPassword"
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <TextField
                                                                name="confirmPassword"
                                                                label="confirm Password"
                                                                type="password"
                                                                autoComplete='new-password'
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                variant='outlined'
                                                                size='small'
                                                                onChange={onChange}
                                                                onBlur={onBlur}
                                                                value={value}
                                                                inputRef={ref}
                                                                error={!!form2.formState.errors.confirmPassword}
                                                                helperText={!!form2.formState.errors.confirmPassword && "Passwords do not match"}

                                                            />
                                                        )}
                                                        rules={{ required: true, validate: isSame, }}
                                                    />
                                                </Box>
                                                <Box mb={2}>
                                                    <Button variant="contained" type='submit' fullWidth>
                                                        {!isLoad2 ?
                                                            "Save Changes"
                                                            :
                                                            <CircularProgress size={25} style={{ color: '#fff' }} />
                                                        }
                                                    </Button>

                                                </Box>
                                            </FormProvider>
                                        </form>
                                    </Box>}
                            </Grid>
                            <Grid item lg={6}
                                sx={{
                                    display: { md: 'none', sm: 'none', xs: 'none', lg: 'flex' },
                                    minHeight: 500,
                                    justifyContent: 'center',
                                }}
                            >
                                <Box
                                    mb={3}
                                    mt={10}
                                >
                                    <Box
                                        component="img"
                                        alt="The house from the offer."
                                        src={image}
                                        sx={{
                                            height: 250,
                                            width: 340
                                        }}
                                        mb={1}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Container>
        </Box >
    )
}

export default LoginPage;
import { Autocomplete, Avatar, Box, Grid, ListItemButton, ListItemText, TextField, Typography } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { updateCommonStates } from '../../../redux/reducers/commonReducer'

const ChatBoxHeader = ({ style, custom, start, center, end, customBody }) => {
    const ref = useRef()
    const dispatch = useDispatch()
    useEffect(() => {
        if (ref) dispatch(updateCommonStates({ name: "chatHeaderHeight", value: ref?.current?.clientHeight }))
    }, [ref])
    return (
        <Box ref={ref} className="chat-header-container flex space-between" style={style}>
            {
                !custom ?
                    <Grid container>
                        <Grid item lg={4} md={4} xs={4}>
                            {start}
                        </Grid>
                        <Grid item lg={4} md={4} xs={4} className="flex-1 align-items-center">
                            {center}
                        </Grid>
                        <Grid item lg={4} md={4} xs={4}>
                            {end}
                        </Grid>
                    </Grid>
                    :
                    <>
                        {customBody}
                    </>
            }
        </Box>
    )
}

export default ChatBoxHeader
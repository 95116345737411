import React, { useEffect, useState } from 'react';
import { Autocomplete, Button, Card, CardContent, Divider, Grid, Paper, Stack, TextField, Typography } from '@mui/material';
import './index.css';
import ChatBoxHeader from './chatBoxHeader';
import { styled } from '@mui/material/styles'
import { Box } from '@mui/system';
import ChatBoxFooter from './chatBoxFooter';
import ChatFeed from './chatFeed/chatFeed';
import CustomListItem from '../../customComponents/CustomListItem';
import { useLandingPageContext } from '../../../utils/customHooks';
import { useNavigate } from 'react-router-dom';
import { getSelectedConversationParticipants } from '../../../utils/customFunctions';
import { coIdData, ctxData } from '../../../utils/config';
import { FormProvider, useForm } from 'react-hook-form';
import CreateGroup from './createGroup';
import { getChatHistory, updateChatReducer } from '../../../actions/actions';
import new_con_image from "../../../assets/images/chat/new_conversation.svg";
import { useSelect } from '@mui/base';
import { useSelector } from 'react-redux';
import CustomTabs from '../../customComponents/customTabs';
import FileListTable from './fileList';
import { getChatAttachmentsListAction } from '../../../actions/apiCallAction';
const StyledCard = styled(Card)({
    // '.css-46bh2p-MuiCardContent-root': {
    //     padding: 0,
    //     paddingLeft: 0,
    //     paddingRight: 0,
    //     paddingTop: 0,
    // }
})
const ChatBox = () => {
    const data = useLandingPageContext();
    const navigate = useNavigate();
    const { isConversationCreated } = useSelector((state) => state.chat)
    const [tab, setTab] = useState("chat")
    const onSelectParticipants = async (e, v) => {
        updateChatReducer("participants", v)
        const res = await data?.chatBoxHeader?.getConversationId(v);
        if (res === null) updateChatReducer("isConversationCreated", "new")
        if (res) {
            getChatHistory(res, data?.ctx);
            updateChatReducer("isConversationCreated", res)
            updateChatReducer("conversationId", res);
        }
    }
    const form = useForm({
        defaultValues: {
            conversationType: '',
            channelName: null,
        },
    });
    const onSubmit = (formData) => {
        const structuredData = [];
        if (data?.chatBoxHeader?.participants?.length > 0) {
            data?.chatBoxHeader?.participants?.map((item) => structuredData.push({ employee: item?.empId, role: "Member" }));
            const payLoad = {
                participants: structuredData,
                conversationType: formData?.conversationType,
                channelName: formData?.channelName
            }
            data?.chatBox?.createAndGetUpdatedConversationList(payLoad, data?.ctx, data?.menu, navigate)
            form.reset();
            updateChatReducer("participants", [])
        }
    }
    const [fileList, setFileList] = useState([]);
    const getFilesList = async (channelId) => {
        try {
            updateChatReducer("chatHistoryLoader", true)
            setTab("file");
            const res = await getChatAttachmentsListAction({ channelId: channelId })
            updateChatReducer("chatHistoryLoader", false)
            if (res) setFileList(res)
        } catch (error) {
            updateChatReducer("chatHistoryLoader", false)
            
        }
       
    }
    useEffect(() => {
        setTab("chat");
        setFileList([])
        updateChatReducer("isConversationCreated", false)
    }, [data?.coId])
    return (
        <StyledCard className="h-100 br-0 chatbox_container_card">
            <CardContent className="h-100 chatbox_card_content">
                <Stack
                    direction="column"
                    className="h-100"
                >
                    <Box className="flex flex-column h-100">
                        <Box>
                            {(data?.coId === coIdData.type1 || data?.coId === coIdData.type2) ?
                                <ChatBoxHeader
                                    custom
                                    customBody={
                                        <Box className="w-100">
                                            <Box container className="w-100 d-flex align-items-center">
                                                <Box className="flex-1">
                                                    <Autocomplete
                                                        multiple
                                                        options={data?.chatBoxHeader?.employeeList}
                                                        id="tags-outlined"
                                                        getOptionLabel={(option) => (
                                                            <CustomListItem
                                                                key={option?.empId}
                                                                primary={`${option?.empTitle?.name} ${option?.firstName} ${option?.lastName}`}
                                                                src={option?.photoLocation}
                                                                secondary={`${option?.employeeType?.name}`}
                                                                type="text" />
                                                        )}
                                                        value={data?.chatBoxHeader?.employeeList?.filter((lan) => {
                                                            const temp = data?.chatBoxHeader?.participants?.filter((i) => i.empId === lan.empId);
                                                            if (temp?.length > 0)
                                                                return lan;
                                                        })}
                                                        onChange={(e, v) => onSelectParticipants(e, v)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                placeholder={data?.chatBoxHeader?.participants?.length === 0 ? "To :" : ""}
                                                                sx={{
                                                                    border: 0
                                                                }}
                                                                size='medium'
                                                                className="bg-white"
                                                                style={{ border: 'none', padding: 1 }} />
                                                        )} />
                                                </Box>
                                                {/* <Button disabled={data?.chatBoxHeader?.participants?.length === 0} sx={{ height: 35, borderRadius: 20}}
                                                onClick={() => data?.chatBoxHeader?.getConversationId()}
                                                variant="contained">Start chat</Button> */}
                                            </Box>
                                        </Box>
                                    }
                                />
                                :
                                <ChatBoxHeader
                                    start={
                                        <CustomListItem primary={getSelectedConversationParticipants(data?.coId, data?.ctx)} />
                                    }
                                    center={
                                        <Box ml={2} className="d-flex h-100 align-items-center">
                                            <Box className={`${tab === "chat" && "selected_tab"} h-100 d-flex align-items-center`}>
                                                <Typography color={`${tab === "chat" ? "Background" : "GrayText"}`} onClick={() => setTab("chat")}
                                                    className={`tab_name`}
                                                >
                                                    Chat
                                                </Typography>
                                            </Box>
                                            <Box ml={3} className={`${tab === "file" && "selected_tab"} h-100 d-flex align-items-center`}>
                                                <Typography
                                                    color={`${tab === "file" ? "Background" : "GrayText"}`}
                                                    onClick={() =>getFilesList(data?.coId)}
                                                    className={`tab_name`}
                                                >
                                                    Files
                                                </Typography>
                                            </Box>
                                        </Box>
                                    }
                                />
                            }
                        </Box>
                        <Divider />
                        {(data?.ctx === ctxData.type2 && data?.coId === coIdData.type2)
                            ? <Box className="w-100">
                                <FormProvider {...form}>
                                    <form onSubmit={form.handleSubmit(onSubmit)}>
                                        <CreateGroup disabled={!data?.chatBoxHeader?.participants?.length > 0 } />
                                    </form>
                                </FormProvider>
                            </Box> : null}
                        <Box className="flex-1 overflow-auto">
                            {tab === "chat"  ? <>
                                {isConversationCreated === "new" ?
                                    <Box className="h-100 d-flex flex-column justify-center align-items-center">
                                        <Box
                                            component="img"
                                            alt="new"
                                            src={new_con_image}
                                            sx={{
                                                height: 250,
                                                width: 340
                                            }}
                                            mb={1}
                                        />
                                        <Typography mt={2} align='center'>Start new Conversation</Typography>
                                    </Box>
                                    :
                                    <ChatFeed />
                                }
                            </>
                                :
                                <Box className="p-1">
                                    <FileListTable data={fileList} />
                                </Box>
                            }
                        </Box>
                        <Box>
                            {tab === "chat" && <ChatBoxFooter />}
                        </Box>
                    </Box>
                </Stack>
            </CardContent>
        </StyledCard>
    )
}
export default ChatBox;
import { updateChatReducer, updatePersistReducer } from "../actions/actions";
import store from "../redux/store";
import { ctxData } from "./config";

export const handlingReceivedMessages = (data) => {
    const { coId, message, callBack } = data;
    const { conversationList, channelList } = store.getState().chat
    if (coId === message?.message?.channelId) {
        updateChatHistory(message?.message)
        callBack && callBack()
    } else {
        const Id = conversationList.find((item) => item.channelId === message?.message?.channelId)
        if (Id) {
            addandUpdatePersistArrayStates("conversationListUnRead", message?.message)
        }
        else {
            const Id2 = channelList.find((item) => item.channelId === message?.message?.channelId)
            if (Id2) addandUpdatePersistArrayStates("channelListUnRead", message?.message)
        }
    }
}

export const addandUpdatePersistArrayStates = (name, value) => {
    const { conversationListUnRead, channelListUnRead } = store.getState().persist

    if (name === "conversationListUnRead") {
        const index = conversationListUnRead.findIndex((item) => item.channelId === value?.channelId)
        if (index >= 0) {
            const temp = [...conversationListUnRead];
            temp[index] = value;
            updatePersistReducer("conversationListUnRead", temp);
        }else{
            const temp = [...conversationListUnRead, value];
            updatePersistReducer("conversationListUnRead", temp);
        }
    }

    else if (name === "channelListUnRead") {
        const index = conversationListUnRead.findIndex((item) => item.channelId === value?.channelId)
        if (index >= 0) {
            const temp = [...channelListUnRead];
            temp[index] = value;
            updatePersistReducer("channelListUnRead", temp);
        }else{
            const temp = [...channelListUnRead,value];
            updatePersistReducer("channelListUnRead", temp);
        }
    }

}

export const updateChatHistory = (msg) => {
    const { chatHistory } = store.getState().chat
    const chatHis = [...chatHistory, msg];
    updateChatReducer("chatHistory", chatHis);
}

export const getUnreadMessage = (ctx, channelId) => {
    const { conversationListUnRead, channelListUnRead } = store.getState().persist;
    if (ctx === ctxData.type1) {
        const temp = conversationListUnRead?.find((item) => item?.channelId === channelId)
        if (temp) return temp?.message
    }
    else if (ctx === ctxData.type2) {
        const temp = channelListUnRead?.find((item) => item?.channelId === channelId)
        if (temp) return temp?.message
    }
    return;
}

export const removeReadMessage = (ctx,channelId) => {
    const { conversationListUnRead, channelListUnRead } = store.getState().persist;
if (ctx === ctxData.type1){
    const temp = conversationListUnRead.filter((item) => item.channelId !== channelId)
    updatePersistReducer("conversationListUnRead", temp);
}else if (ctx === ctxData.type2){
    const temp = channelListUnRead.filter((item) => item.channelId !== channelId)
    updatePersistReducer("channelListUnRead", temp);

}

}
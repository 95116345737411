import { baseUrl } from '../utils/config';
import axios from './axiosService';

export const employeeListService = async (data) => {
    let result;
    await axios({
        method: "POST",
        url: `${baseUrl}users/listDoctors/`,
        data
    }).then((res) => {
        if (res?.data?.status === 200) result = res?.data?.data?.items
    })
    return result
}
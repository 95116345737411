import { configureStore } from '@reduxjs/toolkit';
import commonReducer from './reducers/commonReducer';
import persistStorageReducer from './reducers/persistReducer';
import chatReducer from './reducers/chatDataReducer';
// 
import { combineReducers } from 'redux'
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';

const reducers = combineReducers({
    persist: persistStorageReducer,
    chat: chatReducer,
    common: commonReducer,
});
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['persist']
}
const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
    reducer: persistedReducer
}); 
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useSelector } from 'react-redux';
import { ctxData } from '../../../utils/config';
import { useLandingPageContext } from '../../../utils/customHooks';
import CustomPopper from '../../customComponents/customPopper';

const ConversationListHeader = () => {
    const {chatHeaderHeight} = useSelector(state => state.common)
    const data = useLandingPageContext()
    return (
        <Box className="flex space-between align-items-center" style={{ height: chatHeaderHeight}}>
            <Box className="ms-1">
                <Typography textTransform="capitalize" variant='body' fontWeight="bold">
                   {data?.ctx === ctxData.type1 ? "Chat" :data?.ctx === ctxData.type2 ? "Channel":"Community app" }
                </Typography>
            </Box>
            {/* <Box className="flex">
                <Box className="me-1">Fi</Box>
                <CustomPopper
                    position="right"
                    button={
                        <Box>Ed</Box>
                    }
                />
            </Box> */}
        </Box>
    )
}
export default ConversationListHeader;
import { createBrowserHistory } from 'history';
const history = createBrowserHistory();


export const baseUrl = process.env.REACT_APP_BASE_URL + '/diahome/v1/api/chat-service/';

export default history;
export const ctxData = {
    type1: 'chat',
    type2: 'community',
    type3: 'webinar'
}
export const menuData = {
    type1: "conversation",
    type2: "files",
}
export const coIdData = {
    type1: "newchat",
    type2: "newchannel"
}
export const channelTypeData = [
    {label:"Global Channel", value:"Global Channel"},
    {label:"Teams Channel", value:"Teams Channel"},
]
export const conversationType = {
    type1:["Group Chat", "One to One Chat"],
    type2:["Global Channel", "Teams Channel"],
    type3:["Internal Webinar", "External Webinar"]
}
export const errorMessages = {
    emptyMsg: 'Please type a message to send'
}
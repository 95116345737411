

import ReplayIcon from '@mui/icons-material/Replay';
import { Box } from '@mui/material';
import { useState } from 'react';
import { updateChatReducer } from '../../../../actions/actions';
import { likePostAction } from '../../../../actions/apiCallAction';
import { getEmployeeId } from '../../../../utils/customFunctions';
const ReplayComponent = ({ chatData, landingPageData }) => {
    const replay = async () => {
        updateChatReducer("isReplay", {isReplay:true,chat:chatData})
    }
    return (
        <Box sx={{
            cursor: "pointer",
        }}
            onClick={replay}
            className="d-flex align-items-center"
        >
            <ReplayIcon />

        </Box>
    )
}
export default ReplayComponent;
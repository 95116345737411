import { Button } from '@mui/material';
import React from 'react';
import Lightbox from "react-image-lightbox";

 const FilePreview = ({open,fileType,signedUrl, closePreview}) => {
    return (
        <>
            {
                open && fileType.includes("pdf") ? (
                    <div
                        style={{
                            position: "fixed",
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            background: "#262626",
                            zIndex: 10000,
                        }}
                    >
                        <Button
                            variant='contained'
                            style={{
                                border: "none",
                                // background: "#262626",
                                float: "right",
                            }}
                            className="text-center text-danger delete"
                            value="Delete"
                            onClick={() => closePreview()}
                        >
                            close
                        </Button>
                        <iframe
                            src={signedUrl}
                            style={{ width: "100%", height: "100%" }}
                        ></iframe>
                    </div>
                ) : open && fileType.includes("image") ? (
                    // <Lightbox
                    //     enableZoom={false}
                    //     mainSrc={signedUrl}
                    //     onCloseRequest={() => closePreview()}
                    // />
                    <div
                    style={{
                        position: "fixed",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        background: "#262626",
                        zIndex: 10000,
                    }}
                >
                    <Button
                        variant='contained'
                        style={{
                            border: "none",
                            // background: "#262626",
                            float: "right",
                        }}
                        className="text-center text-danger delete"
                        value="Delete"
                        onClick={() => closePreview()}
                    >
                        close
                    </Button>
                    <iframe
                        src={signedUrl}
                        style={{ width: "100%", height: "100%" }}
                    ></iframe>
                </div>
                ) : null
            }
        </>
    )
}
export default FilePreview;
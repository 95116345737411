import React, { useState } from 'react';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Box } from '@mui/system';
import { DownloadFileWithFileName, getSignedUrl, getUserFullName } from '../../../utils/customFunctions';
import moment from 'moment';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import FilePreview from '../../customComponents/filePreview';

const FileListTable = ({ data }) => {
    const [signedUrl, setSignedUrl] = useState({
        open: false,
        url: "",
        fileType: "",
    });
    const onPreview = async (id, fileType) => {
        const url = await getSignedUrl(id)
        if (url) setSignedUrl({
            open: true,
            url: url,
            fileType: fileType
        })
    }
    return (
        <Box className="h-100">
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {/* <TableCell>Type</TableCell> */}
                            <TableCell align="left">S.no</TableCell>
                            <TableCell align="left">File name</TableCell>
                            <TableCell align="left">Uploaded by</TableCell>
                            <TableCell align="left">Last modified on</TableCell>
                            <TableCell align="left">download</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.map((row, key) => (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell align="left">{key + 1}</TableCell>
                                <TableCell
                                align="left"
                                    sx={{
                                        cursor: "pointer"
                                    }}
                                    onClick={() => onPreview(row?.file?.id, row?.file?.fileType)}
                                    >
                                    {row.file?.filename}
                                </TableCell>
                                <TableCell align="left">{getUserFullName(row.createdBy)}</TableCell>
                                <TableCell align="left">{moment(row.file?.updatedOn).format("DD-MM-YYYY")}</TableCell>
                                <TableCell align="left">
                                    <IconButton
                                        onClick={() => DownloadFileWithFileName(row?.file?.id, row?.file?.fileType, row?.file?.filename)}
                                    >
                                        <DownloadForOfflineIcon />
                                    </IconButton>
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <FilePreview
                open={signedUrl?.open}
                signedUrl={signedUrl?.url}
                fileType={signedUrl?.fileType}
                closePreview={() =>
                    setSignedUrl({
                        ...signedUrl,
                        open: false,
                    })
                }
            />
        </Box>
    )
}

export default FileListTable;
import { Avatar, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { removeReadMessage } from '../../utils/customChatFunctions';

const CustomListItem = ({ primary, primary2, secondary, onClick, src, selected, type, endIcon, unRead, ctx, channelId }) => {
    const onAction = () => {
        onClick();
        unRead && removeReadMessage(ctx, channelId)
    }
    return (
        <>
            {type === "text" ?
                <ListItemText
                    onClick={onClick}
                    selected={selected}
                >
                    <Box className="d-flex align-items-center">
                        <Avatar src={src} alt="image" />
                        <ListItemText
                            disableTypography
                            primary={
                                <Box className="d-flex align-items-center">
                                    <Typography marginLeft={2} noWrap textOverflow="ellipsis" variant="subtitle2" fontSize="small" color="Highlight">{primary}</Typography>
                                    {primary2}
                                </Box>
                            }
                            secondary={
                                <Typography marginLeft={2} sx={{
                                    flexWrap: 'wrap',
                                    wordBreak: 'break-all'
                                }}
                                    fontSize="small" wrap textOverflow="ellipsis" variant="subtitle2" color="GrayText">{secondary}</Typography>}
                        />
                        {endIcon}
                    </Box>
                </ListItemText>
                :
                <ListItemButton
                    onClick={onAction}
                    selected={selected}
                >
                    <Avatar src={src} alt="image" />
                    <ListItemText
                        disableTypography
                        primary={<Typography marginLeft={2} className="" fontWeight={unRead ? "bold" : ""} noWrap textOverflow="ellipsis" variant="subtitle2" style={{ color: 'black', fontSize: '0.875rem' }}>{primary}</Typography>}
                        secondary={<Typography marginLeft={2} fontWeight={unRead ? "bold" : ""} noWrap textOverflow="ellipsis" variant="subtitle2" style={{ color: 'grey', fontSize: '0.875rem' }}>
                            {unRead ? unRead : secondary}
                        </Typography>}
                    />
                    {endIcon}
                </ListItemButton>
            }
        </>
    )
}

export default CustomListItem;

import axios from './axiosService';
import { baseUrl } from '../utils/config';
import {saveAs} from 'file-saver';

export const fileAttachmentUploadURLGenService =  async (data) => {
    let url;
    let fileName;
    let fileObj;
    try {
       await axios({
        method: 'POST',
        url: `${baseUrl}file-storage/v2/get-signed-url/`,
        data: data,
      })
        .then((res) => {
          if (res.data.status >= 200 && res.data.status < 400) {
            fileObj=res.data.data
            url = res.data.data.signedUrl;
            fileName = res.data.data.filename;
          }
        })
        .catch((err) => {
        });
    } catch (error) {
    }
    return [url, fileName,fileObj];
  };

  export const  fileAttachmentUploadService =  (file, url, fileName, setLoad) => {
    console.log( url, 'file, url, fileName');
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        axios
          .request({
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Content-Type': file?.type, // This is set to application/flac
            },
            method: 'PUT',
            data: reader.result,
            url: url,
            timeout: 0,
            onUploadProgress: (progressEvent) => {
              const uploadProgress = (progressEvent.loaded / progressEvent.total) * 100;
              setLoad(uploadProgress)
              console.log(uploadProgress, 'uploadProgress');
            },
          })
          .then((response) => {
            resolve(fileName);
            // console.log(response, 'fileUpload success');
          })
          .catch((err) => reject(err));
      };
      reader.onerror = (err) => {
        err = { error: { message: 'file read error' } };
        reject(err);
      };
      reader.readAsArrayBuffer(file);
    });
  };

  export const fileAttachmentDownloadURLGenService =  async (fileId) => {
    // const encodedFileName = encodeURIComponent(fileName);
    let signedUrl;
    try {
       await axios({
        method: 'GET',
        url: `${baseUrl}file-storage/v2/get-signed-url/?id=${fileId}`,
      })
        .then((res) => {
          if (res.data.status >= 200 && res.data.status < 400) {
            signedUrl = res.data.data.signedUrl;
          }
        })
        .catch(() => {});
    } catch (error) {
      // console.log(error, 'error');
    }
    return signedUrl;
  };


  export const fileAttachmentDownloadByUrlService = async (id, fileType, fileName) => {
    try {
       await axios({
        method: 'GET',
        url: `${baseUrl}file-storage/v2/download-file/?id=${id}`,
        responseType: 'arraybuffer',
      })
        .then((res) => {
          if (res.status >= 200) {
            if (res.data) {
              const blob = new Blob([res.data], { type: fileType });
              saveAs(blob, fileName);
            }
          }
        })
        .catch(() => {
        });
    } catch (error) {
      // console.log(error.res, 'error');
    }
  };


  export const getChatAttachmentsListService = async (data) => {
    let result;
    await axios({
      method: "POST",
      url: `${baseUrl}diahome-community/conversation/attachments/all/list/`,
      data,
    }).then((res) => {
      if (res?.data?.status === 200) result = res?.data?.data?.items
    }).catch(() => {})
    return result
  }
import { Card, CardContent, Divider } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import './index.css';
import ConversationListHeader from './conversationListHeader';
import { useLandingPageContext } from '../../../utils/customHooks';
import { useLocation } from 'react-router-dom';
import CustomListItem from '../../customComponents/CustomListItem';
import { coIdData, ctxData } from '../../../utils/config';
import { getAllUserNameCombined, getUserFullName } from '../../../utils/customFunctions';
import { updateChatReducer } from '../../../actions/actions';
import Loader from '../../customComponents/loader';
import { getUnreadMessage } from '../../../utils/customChatFunctions';
const ConversationList = () => {
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const coId = searchParams.get('co_id');
    const { ctx, ConversationList } = useLandingPageContext();
    const openNewChat = (coId) => {
        ConversationList?.openChat(coId);
        ConversationList?.clearChatHistory();
    }
    return (
        <Card className="convlist_container_card">
            <CardContent className="convlist_container_cardcontent">
                <Box className="convlist_container_box">
                    <Box>
                        <ConversationListHeader />
                    </Box>
                    <Divider />
                    <Box className="convlist_body">
                        {ConversationList.conversationListLoader ?
                            <Box className="d-flex justify-content-center align-items-center h-100">
                                <Loader />
                            </Box>
                            :
                            <Box>
                                {ctx === ctxData.type1 ?
                                    <CustomListItem primary={"New chat"} onClick={() => openNewChat(coIdData.type1)} selected={coId === coIdData.type1} />
                                    : ctx === ctxData.type2 ?
                                        <CustomListItem primary={"Create channel"} onClick={() => openNewChat(coIdData.type2)} selected={coId === coIdData.type2} />

                                        : null
                                }
                                {ConversationList?.conversationList?.map((conversation, key) => (
                                    <CustomListItem
                                        key={key}
                                        primary={conversation.channelName ? conversation.channelName : getAllUserNameCombined(conversation?.participants)}
                                        secondary={`${conversation?.lastMessageInfo?.lastMessage || ""}`}
                                        unRead={getUnreadMessage(ctx, conversation?.channelId)}
                                        onClick={() => {
                                            ConversationList?.openChat(conversation?.channelId);
                                            updateChatReducer("selectedConversation", conversation);
                                        }}
                                        selected={coId === conversation?.channelId}
                                        ctx={ctx}
                                        channelId={conversation?.channelId}
                                    />
                                ))}
                            </Box>}

                    </Box>
                </Box>
            </CardContent>
        </Card>
    )
}
export default ConversationList;
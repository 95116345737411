import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import './index.css'
import { useLandingPageContext } from '../../../utils/customHooks';
import { useNavigate } from 'react-router-dom';
import { Badge } from '@mui/material';
import { ctxData, menuData } from '../../../utils/config';
export default function VerticalAppBar() {
    const data = useLandingPageContext()
    const navigate = useNavigate()
    const navigateToChat = () => navigate('/conversation?ctx=chat')
    const navigateToFiles = () => navigate('/files')
    if (!data?.ctx && data?.menu !== menuData.type2) navigateToChat();
    React.useEffect(() => {
        if(data?.menu === menuData.type2)data?.VerticalAppBar?.getAllFilesList()
    }, [data?.menu])
    return (
        <Box sx={{ flexGrow: 1, height: '100%' }}>
            <AppBar position="static" className="vertical_appbar">
                <Toolbar className="vertical_toolbar" >
                    <IconButton
                        size="small"
                        edge="start"
                        aria-label="menu"
                        className='vertical_nav_icon'
                        color={data?.ctx === ctxData.type1 ? "primary" : "default"}
                        onClick={() => data?.VerticalAppBar.onClickMenu("chat")}
                    >
                        <Badge badgeContent={data?.VerticalAppBar?.conversationListUnRead?.length} color="primary">
                            <ChatOutlinedIcon color={data?.ctx === ctxData.type1 ? "primary" : "action"} />
                        </Badge>
                        <Typography className="vertical_nav_icon_name" variant='subtitle2'>Chat</Typography>
                    </IconButton>
                    <IconButton
                        size="small"
                        edge="start"
                        aria-label="menu"
                        className='vertical_nav_icon'
                        color={data?.ctx === "community" ? "primary" : "default"}
                        onClick={() => data?.VerticalAppBar.onClickMenu("community")}
                    >
                        <Badge badgeContent={data?.VerticalAppBar?.channelListUnRead?.length} color="primary">
                        <GroupsOutlinedIcon color={data?.ctx === ctxData.type2 ? "primary" : "action"}/>
                        </Badge>
                        <Typography className="vertical_nav_icon_name" variant='subtitle2'>Community</Typography>
                    </IconButton>
                    <IconButton
                        size="small"
                        edge="start"
                        aria-label="menu"
                        className='vertical_nav_icon'
                    // color={data?.ctx === "community" ? "primary" : "default"}
                    // onClick={navigateToCommunity}
                    >
                        <CalendarMonthOutlinedIcon />
                        <Typography className="vertical_nav_icon_name" variant='subtitle2'>Calendar</Typography>
                    </IconButton>
                    <IconButton
                        size="small"
                        edge="start"
                        aria-label="menu"
                        className='vertical_nav_icon'
                        onClick={() =>{
                            data?.VerticalAppBar?.getAllFilesList()
                            data?.VerticalAppBar?.clearData()
                            navigateToFiles()

                        }}
                    color={data?.menu === menuData.type2 ? "primary" : "default"}
                    >
                        <InsertDriveFileOutlinedIcon  color={data?.menu === menuData.type2 ? "primary" : "action"} />
                        <Typography className="vertical_nav_icon_name" variant='subtitle2'>Files</Typography>
                    </IconButton>
                </Toolbar>
            </AppBar>
        </Box>
    );
}

import { Grid, IconButton, ListItem, ListItemText, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import CustomListItem from '../../../customComponents/CustomListItem';
import fileImg from "../../../../assets/images/filePreview/file.png"
import { DownloadFileWithFileName, getEmployeeId, getSenderDetailInChat, updateChatData } from '../../../../utils/customFunctions';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import ThumbUpComponent from './thumbUp';
import { ctxData } from '../../../../utils/config';
import ReplayComponent from './replayComponent';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { updateChatReducer } from '../../../../actions/actions';

const MyMessage = ({ chat, onPreview, landingPageData,start,isReplay }) => {
    const {receivedMsgIdToUpdateMsg} = useSelector(state => state.chat);
    const [chatData,setChatData] = useState(chat);
    useEffect(() => {
        if (receivedMsgIdToUpdateMsg && receivedMsgIdToUpdateMsg === chat?.id) {
            updateChatData({channelId:chat?.channelId, objId:chat?.id,setState:setChatData})
            updateChatReducer("receivedMsgIdToUpdateMsg", null)
        }
    }, [receivedMsgIdToUpdateMsg])
    return (
        <Box className="my_message_container">
            <ListItem>
                <Grid container className={`${start ? 'my_message_start':'my_message_end'}`}>
                    <Grid item className="message_container">
                        <ListItemText
                            disableTypography
                            primary={
                                <Box className="flex flex-end align-items-center">
                                { isReplay?.isReplay ?
                                   <Typography fontWeight="bold" fontSize="0.8rem" marginLeft={2} variant="subtitle2">
                                       {isReplay?.chat?.sender === getEmployeeId() ? "You" :getSenderDetailInChat(isReplay?.chat?.sender)?.name}
                                    </Typography>
                                    :
                                    <Typography fontWeight="bold" fontSize="0.8rem" marginLeft={2} variant="subtitle2">
                                        You
                                    </Typography>}
                                    <Typography fontSize="0.7rem" color="GrayText" marginLeft={2} variant="subtitle2">
                                        {moment(chatData?.createdOn).format('DD-MM-YYYY hh:mm A')}
                                    </Typography>
                                    <Box className="flex-1"></Box>
                                    {landingPageData?.ctx === ctxData.type2 &&
                                        <Box className="flex-1 d-flex flex-end">
                                        <Typography style={{ float: 'right' }} fontSize="0.5rem" color="GrayText" marginLeft={2} variant="subtitle2">
                                        <ReplayComponent chatData={chatData} landingPageData={landingPageData} />
                                        </Typography>
                                        <Typography style={{ float: 'right' }} fontSize="0.5rem" color="GrayText" marginLeft={2} variant="subtitle2">
                                            <ThumbUpComponent chatData={chatData} landingPageData={landingPageData} />
                                        </Typography>
                                        </Box>
                                    }
                                </Box>
                            }
                            secondary={
                                <Box className="my_message_text">
                                    <Typography className="word-break-all" marginLeft={2} marginTop={1} variant="subtitle2">
                                        {chatData?.message}
                                    </Typography>
                                </Box>
                            }
                        />

                        {chatData?.attachments?.length > 0 &&
                            chatData?.attachments.map((file, key) => (
                                <Box className="my_message_attchment_container" sx={{ p: 1 }}>
                                    <CustomListItem
                                        type="text"
                                        src={fileImg}
                                        key={key}
                                        primary={
                                            <span className="on_hover_text" style={{ cursor: 'pointer' }} onClick={() => onPreview(file?.file?.id, file?.file?.fileType)}>{file?.file?.filename}</span>
                                        }
                                        endIcon={
                                            <IconButton onClick={() => DownloadFileWithFileName(file?.file?.id, file?.file?.fileType, file?.file?.filename)}>
                                                <DownloadForOfflineIcon />
                                            </IconButton>
                                        }
                                    />
                                </Box>
                            ))
                        }
                    </Grid>
                </Grid>
            </ListItem >
        </Box>
    )
}

export default MyMessage
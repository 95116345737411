export const colors = {
    background: '#edf0f4',
    primary:'#edf0f4',
    white: '#ffffff',
    red: 'red',
    black:{
        main: 'black',
        lightBlack: '#313949',
    },
    font:{
        white: '#ffffff',
        black: 'black'
    },
    grey:{
        ebebeb:'#ebebeb'
    }
}
export const styles = {
    boxShadow_white : '0 1px 3px #dcdfe2'
}

import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { likePostAction } from '../../../../actions/apiCallAction';
import CustomPopOver from '../../../customComponents/customPopOver';
import { getEmployeeId, getEmployeeName } from '../../../../utils/customFunctions';
const ThumbUpComponent = ({ chatData, landingPageData }) => {
    const [likeCount, setLikeCount] = useState(chatData?.likes || []);
    const like = async () => {
        const res = await likePostAction(chatData?.channelId, chatData?.id)
        if (res === true) setLikeCount((prev) => {
            const temp = [...prev, getEmployeeId()];
            const likeData = {
                channelId: chatData?.channelId,
                message: `liked by ${getEmployeeName()}`,
                sender: getEmployeeId(),
                refresh: true,
                id: chatData?.id
            }
            landingPageData.sendMessageLikeAndCommentUpdate(likeData, chatData?.channelId)
            return temp
        })
        else if (res === false) setLikeCount((prev) => {
            const temp = prev?.filter((item) => item !== getEmployeeId())
            const likeData = {
                channelId: chatData?.channelId,
                message: `liked by ${getEmployeeName()}`,
                sender: getEmployeeId(),
                refresh: true,
                id: chatData?.id
            }
            landingPageData.sendMessageLikeAndCommentUpdate(likeData, chatData?.channelId)
            return temp
        })
    }
    useEffect(() => {
        if (chatData) setLikeCount(chatData?.likes || [])
    }, [chatData])
    return (
        <Box sx={{
            cursor: "pointer",
        }}
            onClick={like}
            className="d-flex align-items-center"
        >
            {
                likeCount?.find((item) => item === getEmployeeId()) ?
                    <ThumbUpIcon fontSize='small' color='warning' />
                    :
                    <ThumbUpOutlinedIcon fontSize='small' />
            }
            <Box sx={{ ml: 1 }}>
                <CustomPopOver chatData={chatData} landingPageData={landingPageData} likeData={likeCount}
                    icon={<Typography>{likeCount?.length > 0 && likeCount?.length}</Typography>}
                />
            </Box>

        </Box>
    )
}
export default ThumbUpComponent;
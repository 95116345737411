

import Cookies from "universal-cookie";
import { updatePersistReducer } from "../actions/actions";
import { getChatDataByIdAction } from "../actions/apiCallAction";
import { removeLoginAuthDetails } from "../redux/reducers/commonReducer";
import store from "../redux/store";
import { fileAttachmentDownloadByUrlService, fileAttachmentDownloadURLGenService } from "../services/fileService";
import { ctxData } from "./config";
const cookies = new Cookies();

export const TriggerAlert = (type, msg) => {
  console.log('trigger Alert');
}
export const getAuthToken = () => {
  const token = cookies.get("authToken")
  return token
}

export const getEmployeeId = () => {
  const empId = localStorage.getItem('empId')
  return empId;
}
export const getEmployeeName= () => {
  const empName = localStorage.getItem('empName')
  return empName;
}

export const getUserFullName = (data) => {
  const name = `${data?.empTitle?.name} ${data?.firstName} ${data?.lastName}`
  return name
}
export const getAllUserNameCombined = (data) => {
  let combinedName = [];
  data?.map((user) => {
    if (getEmployeeId() !== user?.employee?.empId) combinedName.push(` ${getUserFullName(user?.employee)} `)
  })
  return combinedName.toString()

}
export const getMatchedUserNameCombined = (data) => {
  let combinedName = [];
  data?.map((user) => {
    if (getEmployeeId() === user?.employee?.empId) combinedName.push(` ${getUserFullName(user?.employee)} `)
  })
  return combinedName.toString()

}

export const getSelectedConversationParticipants = (coId, ctx, empId) => {
  const { conversationList, channelList } = store.getState().chat
  let selected;
  if (ctxData.type1 === ctx) selected = conversationList?.find((item) => item.channelId === coId)
  if (ctxData.type2 === ctx) selected = channelList?.find((item) => item.channelId === coId)
  if (selected) {
    if (empId) return getMatchedUserNameCombined(selected?.participants);
    else return getAllUserNameCombined(selected?.participants);
  }
  return;
}

export const getIsConverstionIdInList = (coId, ctx) => {
  const { conversationList, channelList } = store.getState().chat
  let selected;
  if (ctx === ctxData.type2) selected = channelList?.find((item) => item.channelId === coId)
  else selected = conversationList?.find((item) => item.channelId === coId)
  if (selected) return selected
  return;
}

export const logOut = (navigate) => {
  updatePersistReducer("basicProfile", null)
  store.dispatch(removeLoginAuthDetails())
  navigate("/");
  window.location.reload();
}
export const DownloadFileWithFileName = async (
  fileId,
  fileType,
  fileName
) => {
  try {
    const signedUrl = await fileAttachmentDownloadURLGenService(fileId);
    // console.log(signedUrl, 'signedUrl');
    if (signedUrl) fileAttachmentDownloadByUrlService(fileId, fileType, fileName);
    // if (signedUrl) window.open(signedUrl);
  } catch (error) {
    // console.log('file download failed');
  }
};
export const getSignedUrl = async (fileId) => {
  let url;
  try {
    const signedUrl = await fileAttachmentDownloadURLGenService(fileId);
    url = signedUrl
  } catch (error) {

  }
  return url;
}

export const getSenderDetailInChat = (senderId) => {
  let detail;
  const { selectedConversation } = store.getState().chat
  const sender = selectedConversation?.participants?.find((item) => item?.employee?.empId === senderId)
  detail = {
    name: `${sender?.employee?.empTitle?.name} ${sender?.employee?.firstName} ${sender?.employee?.lastName}`,
    src: sender?.employee?.photoLocation,
    email: sender?.employee?.emailAddress,
    mobile: sender?.employee?.mobileNumber,
  }
  return detail;
}
// global Use
export const updateChatData =  async (data) => {
  const {channelId, objId, setState} = data
  const res = await getChatDataByIdAction(channelId, objId);
  if (res) setState(res);
}
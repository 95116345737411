import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

const Loader = () => {
    return (
        <Box className="d-flex justify-center align-items-center h-100 flex-1">
            <CircularProgress />
        </Box>
    )
}

export default Loader
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { updateChatReducer } from "../actions/actions";
import { baseUrl } from "../utils/config";
import { getAuthToken } from "../utils/customFunctions";

export const getAgoraRTMTokenService = async (userId) => {
  let RTMtoken;
  await axios({
    method: "get",
    url: `${baseUrl}chat-token/generate/rtm?user=${userId}`,
    headers: {
      "Content-Type": "application/json",
      applicationLabel: "Caretaker-Service",
      correlationId: uuidv4(),
      Authorization: `Bearer ${getAuthToken()}`,
    }
  })
    .then((response) => {
      console.log(response, 'getAgoraRTMToken');
      if (response.data.status === 200) {
        RTMtoken = response.data.data.data;
      }
    })
    .catch(() => { });
  return RTMtoken;
};

export const getMyConversationService = async (data) => {
  updateChatReducer("conversationListLoader", true);
  let result;
  await axios({
    method: "POST",
    // url: `${process.env.REACT_APP_CHAT_BASE_URL}diahome-community/conversation/all/list/`,
    url: `${baseUrl}diahome-community/conversation/all/list/`,
    headers: {
      "Content-Type": "application/json",
      applicationLabel: "Chat-Service",
      correlationId: uuidv4(),
      Authorization: `Bearer ${getAuthToken()}`,
    },
    data
  }).then((res) => {
    updateChatReducer("conversationListLoader", false);
    if (res?.data?.status === 200) result = res?.data?.data?.items
  }).catch(() => updateChatReducer("conversationListLoader", false))
  return result
}

export const createConversationService = async (data) => {
  let result;
  await axios({
    method: "POST",
    // url: `${process.env.REACT_APP_CHAT_BASE_URL}diahome-community/conversation/all/list/`,
    url: `${baseUrl}diahome-community/conversation/create/`,
    headers: {
      "Content-Type": "application/json",
      applicationLabel: "Chat-Service",
      correlationId: uuidv4(),
      Authorization: `Bearer ${getAuthToken()}`,
    },
    data
  }).then((res) => {
    if (res?.data?.status === 200 || res?.data?.status === 201) result = res?.data?.data?.channelId
  })
  return result
}

export const getConversationIdService = async (data) => {
  let result;
  await axios({
    method: "POST",
    // url: `${process.env.REACT_APP_CHAT_BASE_URL}diahome-community/conversation/all/list/`,
    url: `${baseUrl}diahome-community/conversation/check/existence/`,
    headers: {
      "Content-Type": "application/json",
      applicationLabel: "Chat-Service",
      correlationId: uuidv4(),
      Authorization: `Bearer ${getAuthToken()}`,
    },
    data
  }).then((res) => {
    if (res?.data?.status === 200) result = res?.data?.data?.channelId
  })
  return result
}

export const getChatHistoryService = async (coId, type) => {
  updateChatReducer("chatHistoryLoader", true);
  let result;
  await axios({
    method: "POST",
    url: `${baseUrl}diahome-community/conversation/${coId}/resources/all/list/?ct=${type}`,
    headers: {
      "Content-Type": "application/json",
      applicationLabel: "Chat-Service",
      correlationId: uuidv4(),
      Authorization: `Bearer ${getAuthToken()}`,
    },
  }).then((res) => {
    updateChatReducer("chatHistoryLoader", false);
    if (res?.data?.status === 200) result = res?.data?.data?.items
  }).catch(() => updateChatReducer("chatHistoryLoader", false))
  return result
}

export const sendMessageService = async (conversationId, data, type) => {
  let result;
  await axios({
    method: "POST",
    url: `${baseUrl}diahome-community/conversation/${conversationId}/resources/send/?ct=${type}`,
    headers: {
      "Content-Type": "application/json",
      applicationLabel: "Chat-Service",
      correlationId: uuidv4(),
      Authorization: `Bearer ${getAuthToken()}`,
    },
    data,
  }).then((res) => {
    if (res?.data?.status === 201) result = res?.data?.data?.id
  }).catch(() => { })
  return result
}
export const likePostService = async (conversationId, objectId) => {
  let result;
  await axios({
    method: "POST",
    url: `${baseUrl}diahome-community/conversation/${conversationId}/resources/like/${objectId}/?ct=post`,
    headers: {
      "Content-Type": "application/json",
      applicationLabel: "Chat-Service",
      correlationId: uuidv4(),
      Authorization: `Bearer ${getAuthToken()}`,
    },
  }).then((res) => {
    if (res?.data?.status === 200) result = res?.data?.data?.like
  }).catch(() => { })
  return result
}
export const commentPostService = async (data, conversationId, objectId) => {
  let result;
  await axios({
    method: "POST",
    url: `${baseUrl}diahome-community/conversation/${conversationId}/resources/comment/${objectId}/?ct=post`,
    headers: {
      "Content-Type": "application/json",
      applicationLabel: "Chat-Service",
      correlationId: uuidv4(),
      Authorization: `Bearer ${getAuthToken()}`,
    },
    data
  }).then((res) => {
    result = true
  }).catch(() => { })
  return result
}
export const getChatByIdService = async (conversationId, objectId) => {
  let result;
  await axios({
    method: "GET",
    url: `${baseUrl}diahome-community/conversation/${conversationId}/resources/get/${objectId}/`,
    headers: {
      "Content-Type": "application/json",
      applicationLabel: "Chat-Service",
      correlationId: uuidv4(),
      Authorization: `Bearer ${getAuthToken()}`,
    },
  }).then((res) => {
    result = res?.data?.data
  }).catch(() => { })
  return result
}
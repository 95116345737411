import { createSlice } from '@reduxjs/toolkit'

export const chatSlice = createSlice({
    name: 'chat',
    initialState: {
        client: undefined,
        channel: undefined,
        isConversationCreated: undefined,
        conversationId: null,
        chatHistory: [],
        chatHistoryLoader: false,
        selectedConversation: undefined,
        allConversationAndChannelList: [],
        conversationList: [],
        channelList: [],
        webinarList: [],
        client1: null,
        isReplay: {
            isReplay: false,
            chat: null,
        },
        refreshAndUpdateSingleMessageById: null,
        chatIdForPostReplayEnable:null,
        test:0

    },
    reducers: {
        updateChatStates: (state, action) => {
            state[action.payload.name] = action.payload.value
        }
    },
});

export const { updateChatStates } = chatSlice.actions;

export default chatSlice.reducer
import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import fileIcon from '../../../assets/images/filePreview/file.png'
import CloseIcon from '@mui/icons-material/Close';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import excelIcon from '../../../assets/images/filePreview/excel.png'
import jsonIcon from '../../../assets/images/filePreview/json.png'
import pdfIcon from '../../../assets/images/filePreview/pdf.png'
import { Button, Card, LinearProgress } from '@mui/material';
import { styles } from '../../../utils/styles';
import { useLandingPageContext } from '../../../utils/customHooks';
import { coIdData, ctxData, errorMessages } from '../../../utils/config';
import CustomListItem from '../../customComponents/CustomListItem';
import { Box } from '@mui/system';
import { getEmployeeId, getSenderDetailInChat } from '../../../utils/customFunctions';
import { updateChatReducer, uploadFileAction } from '../../../actions/actions';
import { useSelector } from 'react-redux';
import MyMessage from './chatFeed/myMessage';
import moment from 'moment';

const ChatBoxFooter = () => {
    const data = useLandingPageContext()
    const { isReplay } = useSelector((state) => state.chat)
    const [message, setMessage] = React.useState({
        replyTo: null,
        channelId: data?.coId,
        sender: getEmployeeId(),
        message: "",
        mentions: [],
        attachments: [],
        // isEdited: false,
        // isActive: true,
        createdOn: "",
    })
    const [replay, setReplay] = React.useState({
        channelId: data?.coId,
        sender: getEmployeeId(),
        message: "",
        mentions: [],
        attachments: [],
    })
    const [isError, setIsError] = React.useState({
        open: false,
        msg: ''
    })
    const showError = (status, msg) => {
        if (status) {
            setIsError({
                open: true,
                msg: msg
            })
            setTimeout(() => showError(false), 2000)
        } else setIsError({ open: false, msg: "" })

    }
    const resetMessage = () => {
        setMessage({
            replyTo: null,
            channelId: data?.coId,
            sender: getEmployeeId(),
            message: "",
            mentions: [],
            attachments: [],
            // isEdited: false,
            // isActive: true,
            createdOn: "",
        })
        setFile(null)
    }
    const resetMessage2 = () => {
        setReplay({
            channelId: data?.coId,
            sender: getEmployeeId(),
            message: "",
            mentions: [],
            attachments: [],
            // isEdited: false,
            // isActive: true,
        })
        setFile(null)
    }
    const sendMessage = () => {
        if ((data?.coId !==coIdData.type1) && (data?.coId !==coIdData.type2)){
            if (message?.message?.length === 0 && message?.attachments?.length === 0) showError(true, errorMessages.emptyMsg)
            else data?.ChatBoxFooter?.sendMessage(message, data?.coId);
            resetMessage()
        }
    }
    const sendReplayMessage = () => {
        if (replay?.message?.length === 0 && replay?.attachments?.length === 0) showError(true, errorMessages.emptyMsg)
        else {
            data?.ChatBoxFooter?.commentPostAction(replay, isReplay?.chat?.channelId, isReplay?.chat?.id);
            updateChatReducer("isReplay", { isReplay: false, chat: null })
        }
        resetMessage2()
    }
    const [file, setFile] = React.useState()
    const [file2, setFile2] = React.useState()
    const [load, setLoad] = React.useState(0)
    const getAndSetFileName = async (file) => {
        setFile(file)
        const fileObj = await uploadFileAction(file, data?.coId, setLoad)
        if (fileObj) setMessage({
            ...message,
            attachments: [...message.attachments, { id: fileObj.id, file: fileObj }]
        })
    }
    const getAndSetFileName2 = async (file) => {
        setFile2(file)
        const fileObj = await uploadFileAction(file, data?.coId, setLoad)
        if (fileObj) setReplay({
            ...replay,
            attachments: [...replay.attachments, { id: fileObj.id, file: fileObj }]
        })
    }
    const clearAttachment = () => {
        setFile(null)
        setMessage({
            ...message,
            attachments: [],
        })
    }
    const clearAttachment2 = () => {
        setFile2(null)
        setReplay({
            ...message,
            attachments: [],
        })
    }
    const fileIconPreview = () => {
        return pdfIcon
        // if (file?.type?.includes("pdf")) return pdfIcon
        // // else if (file?.type?.includes("img")) return <ImageIcon/>
        // else if (file?.type?.includes("spreadsheetml")) return excelIcon
        // else return fileIcon
    }
    React.useEffect(() => {
        resetMessage()
    }, [data?.coId])
    return (
        <Card sx={{ p: 2 }} className="chatbox_footer_card">
            {isError.open && <Paper className="error_message_container">
                {isError.msg}
            </Paper>}
            {isReplay.isReplay &&
                <Paper className="error_message_container">
                    <Box>
                        <IconButton
                            onClick={() => {
                                updateChatReducer("isReplay", { isReplay: false, chat: null });
                                resetMessage2();
                            }}>
                            <CloseIcon />
                        </IconButton>
                        <MyMessage isReplay={isReplay} start={"flex-start"} landingPageData={{ ctx: ctxData.type1 }} chat={isReplay?.chat} date={moment(isReplay?.chat?.createdOn).format('DD-MM-YYYY hh:mm A')} message={isReplay?.chat?.message} />
                    </Box>
                </Paper>}
            {file &&
                <Paper className="error_message_container">
                    <Box className="attach_file_container">
                        <CustomListItem type="text"
                            primary={file?.name}
                            src={fileIcon}
                            endIcon={(
                                <IconButton onClick={clearAttachment}>
                                    <CloseIcon />
                                </IconButton>
                            )} />
                    </Box>
                    <Box className="attach_file_Loader">
                        <LinearProgress variant="determinate" value={load} />
                    </Box>
                </Paper>
            }
            {file2 &&
                <Paper className="error_message_container">
                    <Box className="attach_file_container">
                        <CustomListItem type="text"
                            primary={file2?.name}
                            src={fileIcon}
                            endIcon={(
                                <IconButton onClick={clearAttachment2}>
                                    <CloseIcon />
                                </IconButton>
                            )} />
                    </Box>
                    <Box className="attach_file_Loader">
                        <LinearProgress variant="determinate" value={load} />
                    </Box>
                </Paper>
            }
            {isReplay.isReplay ?
            <form onSubmit={(e) => {
                e.preventDefault()
                sendReplayMessage()
            }}>
                <Paper
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%', boxShadow: styles.boxShadow_white }}
                >

                    <label htmlFor="upload-photo">
                        <input
                            style={{ display: 'none' }}
                            id="upload-photo"
                            name="upload-photo"
                            type="file"
                            onChange={(e) => {
                                if (e.target?.files?.length > 0) getAndSetFileName2(e.target?.files[0])
                            }}
                        />
                        <Button component="span" sx={{ p: '10px' }}>
                            <AttachFileOutlinedIcon />
                        </Button>
                    </label>

                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Type a new message"
                        inputProps={{ 'aria-label': 'Type a new message' }}
                        value={replay?.message}
                        onChange={(e) => {
                            setReplay({
                                ...replay,
                                message: e.target.value
                            })
                        }}
                    />
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

                    <Button type="submit" onClick={sendReplayMessage} component="span" sx={{ p: '10px' }}>
                        <SendOutlinedIcon />
                    </Button>
                </Paper>
            </form>
                : <form onSubmit={(e) => {
                    e.preventDefault()
                    sendMessage()
                }}>
                    <Paper
                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%', boxShadow: styles.boxShadow_white }}
                    >

                        <label htmlFor="upload-photo">
                            <input
                                style={{ display: 'none' }}
                                id="upload-photo"
                                name="upload-photo"
                                type="file"
                                onChange={(e) => {
                                    if (e.target?.files?.length > 0) getAndSetFileName(e.target?.files[0])
                                }}
                            />
                            <Button component="span" sx={{ p: '10px' }}>
                                <AttachFileOutlinedIcon />
                            </Button>
                        </label>
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Type a new message"
                            inputProps={{ 'aria-label': 'Type a new message' }}
                            value={message?.message}
                            onClick={() =>{
                                if (data?.ctx === ctxData.type1) data?.ChatBoxFooter?.selectConversation()
                            }}
                            onChange={(e) => {
                                setMessage({
                                    ...message,
                                    message: e.target.value
                                })
                            }}
                        />
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                        <Button type="submit" onClick={sendMessage} component="span" sx={{ p: '10px' }}>
                            <SendOutlinedIcon />
                        </Button>
                    </Paper>
                </form>}
        </Card>

    )
}
export default ChatBoxFooter;
import { updatePersistReducer } from '../actions/actions';
import { updateLoginAuthDetails } from '../redux/reducers/commonReducer';
import { baseUrl } from '../utils/config';
import axios from './axiosService';

export const LoginService = (data, navigate, setIsAuth) => async (dispatch, getState) => {
    setIsAuth(true)
    await axios({
        method: 'POST',
        url: `${baseUrl}employee/login/`,
        data
    }).then(async (response) => {
        setIsAuth(false)
        if (response.data.status === 200) {
            updatePersistReducer("basicProfile",response.data?.data?.basicProfile )
            await dispatch(updateLoginAuthDetails(response.data.data))
            navigate('/conversation')
        }
    }).catch(() => setIsAuth(false))
}

export const SendOtpService = async (userName,  setIsAuth2) => {
    setIsAuth2(true)
    let result;
    await axios({
        method: "PUT",
        url: `${baseUrl}employee/forgot-password/`,
        data: {
            username: userName
        }
    }).then((response) => {
        setIsAuth2(false)
        if (response.data.status === 200) result = response
    }).catch(()=> setIsAuth2(false))
    return result;
}
export const ChangePasswordService = async (data,setIsLoad2) => {
    setIsLoad2(true)
    let result;
    await axios({
        method: "PUT",
        url: `${baseUrl}employee/verify-otp/`,
        data: data
    }).then((response) => {
        setIsLoad2(false)
        if (response.data.status === 201) result = response
    }).catch(()=>setIsLoad2(false))
    return result;
}

import { createSlice } from '@reduxjs/toolkit'
import Cookies from 'universal-cookie';

const cookies = new Cookies();
export const commonSlice = createSlice({
  name: 'common',
  initialState: {
    authToken: "",
    chatHeaderHeight:"",
    backDrop: false,
    firstRenderLandingPage:true,
    firstRenderAgoraInit:true,
  },
  // Redux Toolkit allows us to write "mutating" logic in reducers. It
  // doesn't actually mutate the state because it uses the Immer library,
  // which detects changes to a "draft state" and produces a brand new
  // immutable state based off those changes
  reducers: {
    updateCommonStates: (state, action) => {
      state[action.payload.name] = action.payload.value
    },
    addAndUpdateCommonStates: (state, action) => {
      state[action.payload.name] += action.payload.value
    },
    updateLoginAuthDetails: (state, action) => {
      state.authToken = action.payload?.accessToken;
      cookies.set('authToken', action.payload?.accessToken, { path: '/' });
      localStorage.setItem("roles", action.payload?.roles)
      localStorage.setItem("empId", action.payload?.basicProfile?.empId)
      localStorage.setItem("authToken", action.payload?.accessToken)
      localStorage.setItem("isTeamOwner", action.payload?.isTeamOwner)
      localStorage.setItem("empName", action.payload?.basicProfile?.empTitle?.name + ' ' + action.payload?.basicProfile?.firstName + ' ' + action.payload?.basicProfile?.lastName)
    },
    removeLoginAuthDetails: (state, action) => {
      state.authToken = ""
      cookies.remove('authToken', { path: '/' });
      localStorage.removeItem("roles")
      localStorage.removeItem("empId")
      localStorage.removeItem("authToken")
      localStorage.removeItem("empName")

    }
  },
})

// Action creators are generated for each case reducer function
export const { updateCommonStates, addAndUpdateCommonStates, updateLoginAuthDetails, removeLoginAuthDetails } = commonSlice.actions

export default commonSlice.reducer
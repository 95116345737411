import React, { useEffect, useState } from 'react';
import AgoraRTM from "agora-rtm-sdk";
import { updateChatReducer, updateCommonReducer } from '../actions/actions';
import { Context } from '../App';
import { LandingPageContext } from '../pages/landingPage/landingPage';
import { getAgoraRTMTokenService } from '../services/chatServices';
import { getEmployeeId } from './customFunctions';
import { useSelector } from 'react-redux';


export const useContextData = () => {
    return React.useContext(Context)
}
export const useLandingPageContext = () => {
    return React.useContext(LandingPageContext)
}

export const useAgoraRTM = () => {
    const { firstRenderAgoraInit } = useSelector((state) => state.common)
    const [messages, setMessages] = useState(null);
    const [receivedMessage, setReceivedMessage] = useState(null);
    const [members, setMembers] = useState(null);
    const [memberJoined, setMemberJoined] = useState(null);
    const [memberLeft, setMemberLeft] = useState(null);
    const [client, setClient] = useState(null);
    const [channel, setChannel] = useState(null);
    const [channelList, setChannelList] = useState([]);
    const init = async () => {
        const token = await getAgoraRTMTokenService(getEmployeeId());
        const client = AgoraRTM.createInstance("2a152b3f32f846aeb15cfb04cc995aa5");
        if (client)
        client.on("MessageFromPeer", function (message, peerId) {
            console.log(message, peerId);
        });
        if (token) await client.login({
            uid: getEmployeeId(),
            token: token,
        });
        setClient(client);
    }
    const joinChannel = async (channelId) => {
        const currentChannel = channelList?.find((item) => item?.channelId === channelId)
        if (!currentChannel) {
            const channel = await client?.createChannel(channelId);
            if (channel) {
                await channel.join();
                setChannel(channel)
                setChannelList((prev) => {
                    const t = [...prev, channel];
                    return t
                })
                channel.getMembers(channelId).then((res) => {
                    // res.includes(caseSheetData?.patientId) && setMemberCount(true);
                    setMembers({
                        channelId: channelId,
                        members: res
                    })
                    console.log(res, channel.channelId, "members list");
                });
            }
            if (channel) {
                channel.on("ChannelMessage", function (message, memberId) {
                    const temp = JSON.parse(message.text);
                    console.log(temp, memberId, 'message updated');
                    setMessages(temp)
                    setReceivedMessage({
                        channelId: channel?.channelId,
                        message: temp
                    })
                });
                channel.on("MemberJoined", function (memberId) {
                    console.log(memberId + " joined the channel");
                    console.log(channel, " joined the channel");
                    setMemberJoined({
                        channelId: channel?.channelId,
                        memberId: memberId
                    })
                });
                channel.on("MemberLeft", function (memberId) {
                    console.log(memberId + " left the channel");
                    setMemberLeft({
                        channelId: channel?.channelId,
                        member: memberId
                    })
                });
            }
        }

    }
    const sendMessage = async (msg, channelId) => {
        let sent;
        const currentChannel = await channelList?.find((item) => item?.channelId === channelId)
        console.log(client,currentChannel, 'clienttttttttttt');
        if (currentChannel) await currentChannel.sendMessage({ text: JSON.stringify(msg) }).then((res) => {
            console.log(res, msg, "message sent from channel");
            sent = true
        })
        return sent
    }
    useEffect(() => {
        updateCommonReducer("firstRenderAgoraInit", false);
        if (!client && !firstRenderAgoraInit) init();
    }, [firstRenderAgoraInit])
    return {
        sendMessage: sendMessage,
        members: members,
        message: messages,
        receivedMessage: receivedMessage,
        memberJoined: memberJoined,
        memberLeft: memberLeft,
        joinChannel: joinChannel,
        client: client,
        channel: channel,
        createdChannelList: channelList
    };
}
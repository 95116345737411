import { createSlice } from '@reduxjs/toolkit'

export const persistSlice = createSlice({
    name: 'persist',
    initialState: {
        authToken: "",
        basicProfile: "",
        conversationListUnRead:[],
        channelListUnRead:[],
    },
    reducers: {
        updatePersistStates: (state, action) => {
            state[action.payload.name] = action.payload.value
        }
    },
});

export const { updatePersistStates } = persistSlice.actions;

export default persistSlice.reducer
import { commentPostService, getChatByIdService, likePostService } from "../services/chatServices";
import { getChatAttachmentsListService } from "../services/fileService"

export const getChatAttachmentsListAction = async (data) => {
    let payLoad = {
        searchCriteria: {
        }
     };
    if (data.channelId) payLoad.searchCriteria.channelId = data.channelId;
    if (data.createdBy) payLoad.searchCriteria.createdBy = data.createdBy;
    const res = await getChatAttachmentsListService(payLoad);
    return res
}
export const likePostAction = async (channelId, objectId) => {
    const res = await likePostService(channelId, objectId)
    return res;
}
export const commentPostAction = async (message, channelId, objectId) => {
    const res = await commentPostService(message, channelId, objectId)
    return res;
}
export const getChatDataByIdAction = async (conversationId, objectId) => {
    const res = await getChatByIdService(conversationId, objectId)
    return res;
}
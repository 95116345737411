import React, { useEffect } from 'react';
import './App.css';
import './customStyles.css'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/login/login';
import { useSelector } from 'react-redux';
import { getAuthToken } from './utils/customFunctions';
import LandingPage from './pages/landingPage/landingPage';
import history from './utils/config';
import BackdropComponent from './components/customComponents/backDrop';

export const Context = React.createContext()
function App() {
  const { authToken } = useSelector(state => state.common)
  const data = {
    ctx: "searchParams",
  }
  return (
    <Context.Provider value={data}>
      <Router history={history}>
        {(getAuthToken() || authToken) ?
          <>
            <Routes>
              <Route path='/:menu' element={<LandingPage />} />
              <Route
                path="*"
                element={<Navigate to="/conversations" replace />}
              />
            </Routes>
          </>
          :
          <Routes>
            <Route path='/' element={<Login />} />
            <Route path="*"
              element={<Navigate to="/" replace />}
            />
          </Routes>
        }
        <BackdropComponent />
      </Router>
    </Context.Provider>
  );
}

export default App;
